import React, { useState } from "react";
import Moralis from "moralis-v1";
import { useMoralis } from "react-moralis";
import HeaderContext from "./context";

import { getSignature, saveDefaultConfig } from "components/HeaderContent/utils";
import nichoLogoPng from "assets/images/new/nichoimg2.png";
import { setAuthToken } from "api/auth";

function HeaderProvider({ children }) {
    const { logout, enableWeb3, authenticate } = useMoralis();
    const [currentChain, setCurrentChain] = useState({});
    // Agreement for terms of use and privacy policy in web2-wallet
    const [showWeb3AuthAgreeModal, setShowWeb3AuthAgreeModal] = useState(false);
    const [hasUserClickTandC, setHasUserClickTandC] = useState(false);

    // Modal controllers
    const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
    const [isNetworkModalVisible, setIsNetworkModalVisible] = useState(false);
    const [showWrongNetworkModal, setShowWrongNetworkModal] = useState(false);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isProviderError, setIsProviderError] = useState(false);

    //------------------- NETWORK Monitor -------------------------------
    const [prevChain, setPrevChain] = useState(null);
    const [gotoDiffURL, setGotoDiffURL] = useState(false);

    // Before connect wallet, need to enable web3 provider
    const enableWeb3First = async (connectorId) => {
        if (connectorId === "web3Auth") {
            // Enable web3 to get user address and chain
            await enableWeb3({
                throwOnError: true,
                provider: connectorId,
                chainId: currentChain.chainId,
                appLogo: nichoLogoPng,
                clientId: process.env.REACT_APP_CLIENT_ID,
            });
        } else {
            // Enable web3 to get user address and chain
            const { account: checkAccountExist } = Moralis;
            if (!checkAccountExist) {
                await enableWeb3({
                    throwOnError: true,
                    provider: connectorId,
                });
            }
        }

        const { account, chainId } = Moralis;

        if (!account) {
            throw new Error(
                "Connecting to chain failed, as no connected account was found"
            );
        }
        if (!chainId) {
            throw new Error(
                "Connecting to chain failed, as no connected chain was found"
            );
        }

        // Get message to sign from the auth api
        const { message } = await Moralis.Cloud.run("requestMessage", {
            address: account,
            chain: parseInt(chainId, 16),
            network: "evm",
        });

        // Save on Localstorage
        saveDefaultConfig(chainId);

        return {
            message,
            account,
            chainId
        }
    };

    // Sign in with web3auth method (Web2Wallet)
    const web3Authenticate = async () => {
        // which means that you have already agreeed web3auth terms of use and disclaimer
        setShowWeb3AuthAgreeModal(false);
        setHasUserClickTandC(false);

        const {message, account, chainId} = await enableWeb3First("web3Auth");

        const user = await authenticate({
            provider: "web3Auth",
            signingMessage: message,
            clientId: process.env.REACT_APP_CLIENT_ID,
            chainId: currentChain.chainId,
            appLogo: nichoLogoPng,
        });

        const signature = getSignature(user);
        await setAuthToken(chainId, account, signature, logout);
    };
    
    return (
        <HeaderContext.Provider 
            value={{ 
                web3Authenticate, enableWeb3First,
                currentChain, setCurrentChain,
                prevChain, setPrevChain,
                gotoDiffURL, setGotoDiffURL,
                showWeb3AuthAgreeModal, setShowWeb3AuthAgreeModal, 
                hasUserClickTandC, setHasUserClickTandC, 
                isProviderError, setIsProviderError,
                isAuthModalVisible, setIsAuthModalVisible,
                isNetworkModalVisible, setIsNetworkModalVisible,
                showWrongNetworkModal, setShowWrongNetworkModal,
                isMobileMenuVisible, setIsMobileMenuVisible
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
}

function useHeader() {
    const context = React.useContext(HeaderContext);
    if (context === undefined) {
        throw new Error("useHeader must be used within a HeaderProvider");
    }
    return context;
}

export { HeaderProvider, useHeader };
