import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import frameGlb from "../../assets/models/frame.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const FrameModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 6], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <Frame color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        // autoRotate
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 4}
        minAzimuthAngle={-Math.PI / 2}
        maxAzimuthAngle={Math.PI / 2}
      />
    </Canvas>
  </div>
);

function Frame(props) {
  const { nodes, materials } = useGLTF(frameGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(materials.defaultMaterial.color, props.color, 0.25, delta)
  );
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.defaultMaterial.geometry}
          material={materials.defaultMaterial}
          rotation={[Math.PI / 2, Math.PI, 0]}
        >
          <Decal
            position={[0, 0, 0]}
            rotation={[3.2, 0, 3.15]}
            scale={0.9}
            map={texture}
            map-anisotropy={16}
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload(frameGlb);
export default FrameModel;
