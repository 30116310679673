import {
    Menu,
    Button,
    Drawer,
    Row,
    Image,
} from "antd";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { useMoralis } from "react-moralis";
import { useHeader } from "providers/HeaderProvider/HeaderProvider";

import { mobileMeunItems } from "../menuData";
import nichoLogoPng from "assets/images/new/nichoimg2.png";

const MobileMenu = () => {
    const { t } = useTranslation();

    const {
        isAuthenticated,
        account,
        logout,
      } = useMoralis();

    const { 
        currentChain, 
        prevChain, 
        isMobileMenuVisible, 
        setPrevChain,
        setIsAuthModalVisible,
        setIsNetworkModalVisible,
        setIsMobileMenuVisible
    } = useHeader();

    const mobMenuClick = (e) => {
        setIsMobileMenuVisible(false);
    };

    const onClose = () => {
        setIsMobileMenuVisible(false);
    };

    return (
        <Drawer
            title=""
            placement="right"
            closable={true}
            onClose={onClose}
            open={isMobileMenuVisible}
            className="mob-right-drawer"
            width={300}
        >
            <div className="mob-logo-wrap">
                <Link to="/" onClick={() => setIsMobileMenuVisible(false)}>
                    <Image preview={false} height={48} src={nichoLogoPng} />
                </Link>

                <div className="drawer-right-close" onClick={() => setIsMobileMenuVisible(false)}>
                    <CloseOutlined />
                </div>
            </div>
            <div className="mob-meun-scroll">
                <Menu
                    mode="inline"
                    theme="dark"
                    items={mobileMeunItems(t, logout)}
                    onClick={mobMenuClick}
                    className="mob-right-menu"
                />
            </div>

            <div className="mob-footer-wrap">
                {!isAuthenticated && !account && (
                    <Button
                        type="primary"
                        onClick={() => setIsAuthModalVisible(true)}
                    >
                        {t("connectWallet")}
                    </Button>
                )}

                <Button
                    type="primary"
                    className="choose-net"
                    onClick={() => {
                        setPrevChain(currentChain);
                        setIsNetworkModalVisible(true);
                        setIsMobileMenuVisible(false);
                    }}
                >
                    <Row align="middle">
                        <span>
                            {prevChain && prevChain.value} &nbsp;&nbsp;&nbsp;{" "}
                            {!prevChain && currentChain && currentChain.value} &nbsp;&nbsp;&nbsp;{" "}
                        </span>
                        <i className="xsj-icon" />
                    </Row>
                </Button>

                <span></span>
            </div>
      </Drawer>
    );
}

export default MobileMenu;