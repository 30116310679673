import {
    Space,
    Alert
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { useHeader } from "providers/HeaderProvider/HeaderProvider";


const ProviderWarn = () => {
    const { t } = useTranslation();
    const { setIsProviderError } = useHeader();

    const showGuide = () => {
        const metamaskExtension =
        "https://medium.com/@NichoNFT/how-to-set-up-your-metamask-and-participate-in-nicho-nft-marketplace-6665a3c483b";
        window.open(metamaskExtension, "_blank");
    };

    return (
        <div className="metamask-setup">
            <Alert
                message="Provider Error"
                description="No provider was found"
                type="error"
                action={
                    <Space direction="vertical" align="end">
                    <span
                        onClick={() => setIsProviderError(false)}
                        style={{ cursor: "pointer" }}
                    >
                        <CloseOutlined />
                    </span>
                    {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a size="small" href="#" primary="true" onClick={showGuide}>
                        {t("needHelp")}
                        </a> 
                    }
                    </Space>
                }
            />
        </div>
    )
}

export default ProviderWarn;