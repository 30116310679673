import { getOwnedNFTsApi } from "api/nftList";
import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useSelector } from "react-redux";

export const useMyNft = () => {
  const [items, setItems] = useState();
  const { network } = useSelector((state) => state.network);
  const { user } = useMoralis();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const getUserItems = async () => {
      if (!user || !network) return;
      try {
        const params = {
          ethAddress: user.get("ethAddress"),
          pageNo: 1,
          pageSize: 50,
        };

        const { records, total } = await getOwnedNFTsApi(params, network);
        // console.log("Owned records: ", records);
        if (records) {
          setItems(records);
          setTotal(total);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserItems();
  }, [user, network]);
  return { items, total };
};
