export const getNumberSuffix = (_value, decimals = 3) => {
    let value = _value;

    if (value === Infinity || value > 100000000) {
        value = 100000000;
    }
    
    switch (true) {
        case value > 1000000000:
            return `${(value / 1000000000).toFixed(0)}B `;
        case value > 1000000:
            return `${(value / 1000000).toFixed(0)}M `;
        case value > 1000:
            return `${(value / 1000).toFixed(decimals)}K `;
        default:
            return value.toFixed(decimals);
    }
};

export const FORMAT_DECIMAL = 2;
export const TOKEN_DECIMAL = 9;
export const FlexRewardPercent = 0.25; // 25%
export const LockedRewardPercent = 1 - FlexRewardPercent;
export const BlocksPerMinute = 12;

export const columns = [
    {
        title: "No",
        dataIndex: "no",
        key: "no",
    },
    {
        title: "User Address",
        dataIndex: "userAddress",
        key: "userAddress",
    },
    {
        title: "Staked Amount",
        dataIndex: "stakedValue",
        key: "stakedValue",
    },
    {
        title: "Percent",
        dataIndex: "percent",
        key: "percent",
    },
];

export const StakingTypeEnum = {
    Locked: 0,
    Flexible: 1
}