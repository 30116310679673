export const productsConfigs = {
  shirt: {
    avaliableColors: ["#080202", "#DDE6ED", "#9DB2BF"],
    sellingPrice: 50,
  },
  coffeeCup: {
    avaliableColors: ["#B79554", "#8d99ae"],
    sellingPrice: 20,
  },
  mug: {
    avaliableColors: ["#080202"],
    sellingPrice: 30,
  },
  cup: {
    avaliableColors: ["#FFB84C"],
    sellingPrice: 20,
  },
  cap: {
    avaliableColors: ["#1D267D"],
    sellingPrice: 20,
  },
  bag: {
    avaliableColors: ["#212A3E"],
    sellingPrice: 70,
  },
  frame: {
    avaliableColors: ["#4C3D3D"],
    sellingPrice: 150,
  },
  skateboard: {
    avaliableColors: [],
    sellingPrice: 220,
  },
};
