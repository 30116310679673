// import request from './request'
import authRequest from './auth_request'


// 
export function getUserListApi(data, network) {
  return authRequest({
    url: `/${network}/user/getUserList`,
    method: 'post',
    data
  })
}

export function getUserDetailApi(data, network) {
  return authRequest({
    url: `/${network}/user/getUserDetail`,
    method: 'post',
    data
  })
}

export function addFollowerApi(data, network) {
  return authRequest({
    url: `/${network}/user/addFollowers`,
    method: 'post',
    data
  })
}

export function getUserCollectionApi(data, network) {
  return authRequest({
    url: `/${network}/collections/getUserCollections`,
    method: 'post',
    data
  })
}

export function getFollowerUserApi(data, network) {
  return authRequest({
    url: `/${network}/user/getFollowers`,
    method: 'post',
    data
  })
}

export function getFollowingUserApi(data, network) {
  return authRequest({
    url: `/${network}/user/getFollowings`,
    method: 'post',
    data
  })
}

export function getFavoriteNFTsApi(data, network) {
  return authRequest({
    url: `/${network}/user/getFavoriteNFTs`,
    method: 'post',
    data
  })
}

export function getFavoriteCollections(data, network) {
  return authRequest({
    url: `/${network}/user/getFavoriteCollections`,
    method: 'post',
    data
  })
}

export function getSoldNFTsApi(data, network) {
  return authRequest({
    url: `/${network}/user/getSoldNFTs`,
    method: 'post',
    data
  })
}