import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import skateboardGlb from "../../assets/models/skateboard.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const SkateboardModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 2.7], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <Skateboard color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.5}
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2.5}
        maxPolarAngle={Math.PI / 4}
        // minAzimuthAngle={-Math.PI / 2.1}
        // maxAzimuthAngle={Math.PI / 2.3}
      />
    </Canvas>
  </div>
);
function Skateboard(props) {
  const { nodes, materials } = useGLTF(skateboardGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(materials.diffuse_Black.color, props.color, 0.25, delta)
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_2.geometry}
        material={materials.diffuse_Black}
        rotation={[-1.5, 1.8, 0.3]}
      >
        <Decal
          position={[0, 0, 0]}
          rotation={[1.5, 0, 1.56]}
          scale={1.4}
          map={texture}
          map-anisotropy={16}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(skateboardGlb);
export default SkateboardModel;
