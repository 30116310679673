import { app_env } from "helpers/networks";

// When switch network, target network domain 
// and current network domain is same or different
export const checkTargetAndCurrentHostname = (targeLink) => {
    if (targeLink.includes("test") && app_env.includes("test")) {
      return "same";
    }

    if (!targeLink.includes("test") && !app_env.includes("test")) {
      return "same";
    }

    return "different"
};


export const wrapLink = (link) => {
    if (link) {
        if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
        } else {
            return `https://${link}`;
        }
    }
};

export const openExternalLink = (link) => {
    if (!link || link === "") {
      return;
    } 
    const url = wrapLink(link);
    window.location.href = url;
};

export const loadDefaultConfig = () => {
    return localStorage.getItem("default_config");
}

export const saveDefaultConfig = (chainId) => {
    localStorage.setItem("default_config", JSON.stringify({
        chainId
    }));
}

// REACT_APP_ENVIRONMENT
export const getDefaultChainId = () => {
    if (app_env === "testnet") {
        // BNB testnet
        return "0x61";
    } else { 
        // BNB mainnet
        return "0x38";
    }
}

export const getSignature = (user) => {
    if (user) {
        const authData = user.get("authData");
        if (authData) {
            const {signature} = authData.moralisEth;
            return signature;
        }
    }
    return null;
}