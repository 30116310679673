import { useState } from "react";
import {
    Button,
    Modal,
    Input,
    Typography
} from "antd";
import { useStake } from "providers/StakeProvider";
import { useMoralis } from "react-moralis";
import { floatFixed, getUTCTimeStamp } from "helpers/Utils";
import { useSelector } from "react-redux";
import {
    nichoTokenABI,
    nichoFarmABI,
} from "contracts/constants";
import { StakingTypeEnum } from "../utils";
import { getDateFromTime } from "helpers/Utils";

const { Title } = Typography;

const percents =[25, 50, 75, 100];
const lockDays =[
    {
        label: '1W',
        weeks: 1
    },
    {
        label: '5W',
        weeks: 5
    },
    {
        label: '10W',
        weeks: 10
    },
    {
        label: '25W',
        weeks: 25
    },
    {
        label: 'Max',
        weeks: 52
    },
];

const weekToSeconds = (wk) => {
    return wk * 7 * 24 * 3600;
}

const StakeModal = () => {
    const { 
        refresh, userData, stakingType, nichoBalance, isStakeModalVisible,
        flexAPR, lockAPR, 
        setRefresh, setIsStakeModalVisible 
    } = useStake();
    const { 
        nichoTokenContract: nichoTokenContractAddress, 
        nichoFarmContract: nichoFarmContractAddress 
    } = useSelector((state) => state.network.contracts);

    const [stakeAmount, setStakeAmount] = useState(0);
    const [lockPeriod, setLockPeriod] = useState(0);
    const [waiting, setWaiting] = useState(false);

    const { Moralis } = useMoralis();

    const waitTs = (modal) => {
        setTimeout(() => {
            modal.destroy();
        }, 3 * 1000);
        return;
    }
    const stakeAmountChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
            setStakeAmount(inputValue);
        }
    };

    const setStakeAmountWithPercent = (percent) => {
        const inputValueFixed = floatFixed((nichoBalance * percent) / 100);
    
        setStakeAmount(inputValueFixed);
    };

    const setLockPeriodChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
            setLockPeriod(inputValue);
        }
    };
    const setLockPeriodAsWeek = (period) => {
        setLockPeriod(period);
    };

    const stakeNicho = async () => {
        try {
            if (stakeAmount === 0) {
                const modal = Modal.error({
                    title: "Error!",
                    content: "Stake amount is not valid!",
                });
                waitTs(modal);
                return;
            }
            // console.log(stakeAmount, nichoBalance)
            if (Number(stakeAmount) > Number(nichoBalance)) {
                const modal = Modal.error({
                    title: "Error!",
                    content: "Insufficient balance!",
                });
                waitTs(modal);
                return;
            }
            // In case of Locked staking, need to validate the lock period
            if (stakingType === StakingTypeEnum.Locked) {
                // Should be 7 in production
                if (lockPeriod <= 0) {
                    const modal = Modal.error({
                        title: "Error!",
                        content: "Locked period cannot be zero!",
                    });
                    waitTs(modal);
                    return;
                }
            }
            setWaiting(true);
        
            const maxAllowance = Moralis.Units.Token("10000000000000000000000", "9");
            let allowance = 0;
        
            const ethers = Moralis.web3Library;
            const web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider
            const signer = web3Provider.getSigner();
        
            const signerAddr = await signer.getAddress();
            const nichoTokenContract = new ethers.Contract(
                nichoTokenContractAddress,
                nichoTokenABI,
                signer
            );
            const nichoFarmContract = new ethers.Contract(
                nichoFarmContractAddress,
                nichoFarmABI,
                signer
            );
        
            allowance = await nichoTokenContract.allowance(
                signerAddr,
                nichoFarmContractAddress
            );
            allowance = Moralis.Units.FromWei(allowance.toString(), 9);
            if (allowance < stakeAmount) {
                const tx = await nichoTokenContract.approve(nichoFarmContractAddress, maxAllowance);
                await tx.wait();
            }
            
            const lockPeriodInseconds = (stakingType === StakingTypeEnum.Flexible) ? 0: weekToSeconds(lockPeriod);
            const tx = await nichoFarmContract.stake(
                Moralis.Units.Token(Number(stakeAmount).toFixed(9).toString(), "9"),
                lockPeriodInseconds
            );
            const txResult = await tx.wait();
            setRefresh(refresh+1);

            const modal = Modal.success({
                title: "Success!",
                content: <span>
                    { `${stakeAmount} NICHO has been staked successfully, This is a transaction hash ${txResult.transactionHash}`}
                </span>,
            });

            setTimeout(() => {
                modal.destroy();
                setStakeAmount(0);
                setIsStakeModalVisible(false);
            }, 3 * 1000);

        } catch (err) {
            console.log(err)
        
            const modal = Modal.error({
                title: "Error!",
                content: err?.data.message,
            });
            waitTs(modal)
        } finally {
            setWaiting(false);
        }
    };

    return (
        <Modal
            open={isStakeModalVisible}
            centered
            footer={false}
            onCancel={() => setIsStakeModalVisible(false)}
        >
            <div className="form-group">
                <div className="form-title">
                    <Title 
                        level={3} type="secondary"
                    >
                        Stake Nicho
                    </Title>
                    <div className="stake-option">
                        { stakingType === StakingTypeEnum.Flexible ? "Flexible": "Locked" }
                    </div>
                 </div>
                <div className="form-item">
                    <div className="stake-header">
                        <div>Stake Amount *</div>
                        <div>Balance : {nichoBalance ?? 0}</div>
                    </div>
                    <div className="stake-content">
                        <div style={{width: "100%"}}>
                            <Input
                                size="small"
                                className="ant-input"
                                value={stakeAmount}
                                onChange={(e) => stakeAmountChange(e)}
                            />
                        </div>
                        <div>NICHO</div>
                    </div>
                    <div className="percentage-btn-group">
                        {
                            percents.map((percent) => {
                                return (
                                    <span
                                        key={percent}
                                        className="percentage-button"
                                        onClick={() => setStakeAmountWithPercent(percent)}
                                    >
                                        {percent >= 100 ? "Max": `${percent}%`}
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    userData && userData.stakedAmount > 0 && (
                        <div className="form-item">
                            <div className="stake-header">
                                <div>Current Staked Amount:</div>
                                <div>{userData.stakedAmount ?? 0} NICHO</div>
                            </div>
                        </div>
                    )
                }

                {(stakingType === StakingTypeEnum.Locked) && (
                    <div className="form-item" style={{marginTop: "0px"}}>
                        <div className="stake-header">
                            <div>Duration *</div>
                        </div>
                        <div className="stake-content">
                            <div style={{width: "100%"}}>
                                <Input
                                    size="small"
                                    className="ant-input"
                                    value={lockPeriod}
                                    onChange={(e) => setLockPeriodChange(e)}
                                />
                            </div>
                            <div>Week</div>
                        </div>

                        <div className="percentage-btn-group">
                            {
                                lockDays.map((lockDay) => {
                                    const { label, weeks } = lockDay;
                                    return (
                                        <span
                                            key={label}
                                            className="percentage-button"
                                            onClick={() => setLockPeriodAsWeek(weeks)}
                                        >
                                            {label}
                                        </span>
                                    )
                                })
                            }
                        </div>
                    </div>
                )}

                <div className="form-item" >
                    {
                        (stakingType === StakingTypeEnum.Flexible) && ( <div className="stake-header">
                        <div>Annual ROI at current rates:</div>
                        <div>
                            {
                                ((parseFloat(userData.stakedAmount ?? "0") + parseFloat(stakeAmount)) * 
                                parseFloat(flexAPR) / 100).toFixed(4)
                            } NICHO
                        </div>
                    </div>)}

                    {
                        (stakingType === StakingTypeEnum.Locked) && ( <div className="stake-header">
                        <div>Expected ROI:</div>
                        <div>
                            {
                                (parseFloat(stakeAmount) * lockPeriod / 52 * parseFloat(lockAPR) / 100).toFixed(4)
                            } NICHO
                        </div>
                    </div>)}

                    {
                        (stakingType === StakingTypeEnum.Locked) && (
                            <div className="stake-header">
                                <div>UNLOCK ON:</div>
                                <div>
                                    {
                                        getDateFromTime(getUTCTimeStamp((parseInt(lockPeriod) * 7).toString()))
                                    }
                                </div>
                            </div>)
                    }
                </div>


                <div className="bottom-btn-group">
                    <Button
                        type="primary"
                        size="small"
                        onClick={(e) => setIsStakeModalVisible(false)}
                        disabled={waiting}
                    >
                        {" "}
                        Cancel{" "}
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={stakeNicho}
                        disabled={waiting}
                        loading={waiting}
                    >
                        {" "}
                        Stake{" "}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default StakeModal;