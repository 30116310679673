import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import bagGlb from "../../assets/models/bag.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const BagModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 150], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <Bag color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.5}
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2.5}
        maxPolarAngle={Math.PI / 4}
      />
    </Canvas>
  </div>
);

function Bag(props) {
  const { nodes, materials } = useGLTF(bagGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(materials.material.color, props.color, 0.25, delta)
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes["Rattan_Tote_Bag_(EMP_(SBH)_27E)_M_0_0"].geometry}
        material={materials.material}
      >
        <Decal
          position={[0, 15, 0]}
          rotation={[0, 0, 0]}
          scale={25}
          map={texture}
          map-anisotropy={16}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(bagGlb);
export default BagModel;
