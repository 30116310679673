import { getAiImages, getAiImagesDetails, getAiModels } from "api/getAiImages";
import { useEffect, useState } from "react";

const useImageGenerator = () => {
  const [generatedImages, setGeneratedImages] = useState([]);
  const [base64GeneratedImages, setBase64GeneratedImages] = useState([]);
  const [imagesIds, setImagesIds] = useState([]);
  const [estimatedGenerateTime, setEstimatedGenerateTime] = useState(null);
  const [hasNoEstimatedTime, setHasNoEstimatedTime] = useState(false);
  const [isBase64ImagesReady, setIsBase64ImagesReady] = useState(false);
  const [avaliableModels, setAvaliableModels] = useState([]);

  const resetDefault = () => {
    setGeneratedImages([]);
    setBase64GeneratedImages([]);
    setImagesIds([]);
    setEstimatedGenerateTime(null);
    setIsBase64ImagesReady(false);
    setHasNoEstimatedTime(false);
  };

  const fetchAiImagesIds = async (imagesDetailsConfigs) => {
    resetDefault();
    // const imagesDetailsConfigs = {
    //   prompt: "beautiful girl",
    //   negativePrompt: "",
    //   modelNo: "3",
    //   height: 512,
    //   width: 512,
    //   quantity: 1,
    //   strength: 0,
    //   cfgScale: 7,
    //   autoOpt: true,
    // };
    const imagesDetails = await getAiImagesDetails(imagesDetailsConfigs);
    setImagesIds(imagesDetails.ids);
    if (imagesDetails.estimatedTimes !== null) {
      setEstimatedGenerateTime(Math.max(...imagesDetails.estimatedTimes));
    } else {
      setHasNoEstimatedTime(true);
    }
  };

  const getAiImagesAfterTimeout = async () => {
    if (imagesIds.length === 0) return;
    const aiImagesConfigs = {
      pageNo: 1,
      pageSize: 10,
      ids: imagesIds,
    };
    const response = await getAiImages(aiImagesConfigs);
    const tempGeneratedImages = [];
    // eslint-disable-next-line
    response.map((image) => {
      tempGeneratedImages.push(image.imageUrl);
    });
    setGeneratedImages(tempGeneratedImages);
  };

  const imageUrlToBase64 = async () => {
    await Promise.all(
      generatedImages.map(async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64GeneratedImages((prev) => [...prev, reader.result]);
        };
        reader.readAsDataURL(blob);
      })
    );
  };

  const retrieveModels = async () => {
    const params = {
      pageNo: 1,
      pageSize: 100,
    };
    const models = await getAiModels(params);
    setAvaliableModels(models?.splice(0, 5));
  };

  useEffect(() => {
    retrieveModels();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (generatedImages.length > 0) {
      imageUrlToBase64().then(() => {
        setIsBase64ImagesReady(true);
      });
    }
    // eslint-disable-next-line
  }, [generatedImages]);

  return {
    fetchAiImagesIds,
    getAiImagesAfterTimeout,
    base64GeneratedImages,
    estimatedGenerateTime,
    isBase64ImagesReady,
    setIsBase64ImagesReady,
    hasNoEstimatedTime,
    avaliableModels,
  };
};

export default useImageGenerator;
