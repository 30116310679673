import React from "react";

const StakeContext = React.createContext({
    isWithdrawModalVisible: false,
    totalLockedTokenAmount: 0, 
    totalFlexTokenAmount: 0, 
    isStakeModalVisible: false,
    totalVolumeLocked:0, 
    nichoBalance: 0,
    stakingType: 0, 
    userData: null,
    flexAPR: 0, 
    lockAPR: 0, 
    refresh: 0, 
    setRefresh: () => {},
    setLockAPR: () => {},
    setFlexAPR: () => {},
    setUserData: () => {},
    setStakingType: () => {},
    setNichoBalance: () => {},
    setTotalVolumeLocked: () => {},
    setIsStakeModalVisible: () => {},
    setTotalFlexTokenAmount: () => {},
    setTotalLockedTokenAmount: () => {},
    setIsWithdrawModalVisible: () => {},

    enableWeb3First: () => {},
    web3Authenticate: ()=> {}
});

export default StakeContext;
