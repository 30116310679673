import buyButtonImg from "assets/images/new/mintToEarn/buy_button.png";
import topImg from "assets/images/new/mintToEarn/top_img.png";
import stakeImg from "assets/images/new/mintToEarn/stake.png";
import yiwenSvg from "assets/images/new/mintToEarn/yiwen.svg";

const StakeBanner = ({apr}) => {
    return (
        <div className="earn-top-content">
            <img className="mint-item-img" src={stakeImg} alt="Nicho AI NFT" />
            <div className="percentage">
                <span className="value">{apr}%</span>
                <span className="value">APR</span>
                <img src={yiwenSvg} alt="Nicho AI NFT" />
            </div>
            <a
                href="https://bscscan.com/token/0x52904d8bB07e72541C854793242D51128043d527"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                <img src={buyButtonImg} alt="Nicho AI NFT" />{" "}
            </a>
            <img className="top-right-img" src={topImg} alt="Nicho AI NFT" />
        </div>
    )
}

export default StakeBanner;