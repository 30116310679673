import {
    Modal, 
    Button,
    Tooltip,
    Divider,
    Typography
} from "antd";
import { useHeader } from "providers/HeaderProvider/HeaderProvider";
import { useTranslation } from 'react-i18next';

const Web3AuthAgreeModalComponent = () => {
    const { t } = useTranslation();
    const { 
        showWeb3AuthAgreeModal, 
        hasUserClickTandC, 
        setHasUserClickTandC, 
        setShowWeb3AuthAgreeModal, 
        web3Authenticate 
    } = useHeader();

    return (
        <Modal
            open={showWeb3AuthAgreeModal}
            footer={null}
            // title="Read Before Using"
            closable={false}
            centered={true}
            onCancel={() => {
                setShowWeb3AuthAgreeModal(false);
                setHasUserClickTandC(false);
            }}
            width={375}
        >
            <div className="web3auth-content">
                <Typography.Title level={3} className="web3auth-content-title">
                    {t("readBeforeUsing")}
                </Typography.Title>
                <Divider style={{ background: "white" }} />
                <Typography.Paragraph style={{ textAlign: "center" }}>
                    {t("pleaseClick")}{" "}
                    <span
                        className="web3auth-link"
                        onClick={() => {
                            window.open(
                            "https://medium.com/@NichoNFT/login-to-nicho-with-your-social-media-accounts-now-b434dcf2ecf2"
                            );
                            setHasUserClickTandC(true);
                        }}
                    >
                    {t("here")}
                    </span>{" "}
                    {t("readTerms")}
                </Typography.Paragraph>
                <div className="web3auth-button-group">
                    <Button
                        style={{ width: "100%", background: "red" }}
                        onClick={() => {
                            setShowWeb3AuthAgreeModal(false);
                            setHasUserClickTandC(false);
                        }}
                    >
                        {t("neverMind")}
                    </Button>
                    {hasUserClickTandC ? (
                    <Button
                        style={{
                        width: "100%",
                        marginTop: "2rem",
                        background: "green",
                        }}
                        onClick={web3Authenticate}
                    >
                        {t("readDisclaimer")}
                    </Button>
                    ) : (
                    <Tooltip
                        title="Please Read the disclaimer first"
                        placement="bottom"
                    >
                        <Button
                            disabled
                            style={{
                                width: "100%",
                                marginTop: "2rem",
                                background: "green",
                            }}
                            onClick={web3Authenticate}
                        >
                        {t("readDisclaimer")}
                        </Button>
                    </Tooltip>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default Web3AuthAgreeModalComponent;