import authRequest from './auth_request'

/**
 * @dev Admin function( user report function )
 * @param { objectId, isReported } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminReportUser(data, network) {
    return authRequest({
        url: `/${network}/user/report`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( user block function )
 * @param { objectId, isBlocked } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminBlockUser(data, network) {
    return authRequest({
        url: `/${network}/user/block`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft report function )
 * @param { objectId, isReported } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminReportNFT(data, network) {
    return authRequest({
        url: `/${network}/nft/report`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft block function )
 * @param { objectId, isBlocked } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminBlockNFT(data, network) {
    return authRequest({
        url: `/${network}/nft/block`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( collections report function )
 * @param { objectId, isReported } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminReportCollection(data, network) {
    return authRequest({
        url: `/${network}/collections/report`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( collections block function )
 * @param { objectId, isBlocked } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminBlockCollection(data, network) {
    return authRequest({
        url: `/${network}/collections/block`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft register function )
 * @param { tokenName, tokenAddress, tokenImage, ownerOf, tokenUri, blockNumber, tokenId, minter, metadata, tokenHash } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminRegisterNFT(data, network) {
    return authRequest({
        url: `/${network}/nft/register`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft recommends save function )
 * @param { idList[] } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminRecommendNFTs(data, network) {
    return authRequest({
        url: `/${network}/nft/recommends/save`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft recommends modify function )
 * @param { id, sorted } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminModifyRecommendNFTs(data, network) {
    return authRequest({
        url: `/${network}/nft/recommends/modify`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( nft recommends delete function )
 * @param { idList[] } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminDeleteRecommendNFTs(data, network) {
    return authRequest({
        url: `/${network}/nft/recommends/delete`,
        method: 'post',
        data
    })
}


/**
 * @dev Admin function( nft recommends save function )
 * @param { idList[] } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminRecommendCollections(data, network) {
    return authRequest({
        url: `/${network}/collections/recommends/save`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( collections recommends modify function )
 * @param { id, sorted } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminModifyRecommendCollections(data, network) {
    return authRequest({
        url: `/${network}/collections/recommends/modify`,
        method: 'post',
        data
    })
}

/**
 * @dev Admin function( collections recommends delete function )
 * @param { idList[] } data 
 * @param { network } network 
 * @returns true or false
 */
export function adminDeleteRecommendCollections(data, network) {
    return authRequest({
        url: `/${network}/collections/recommends/delete`,
        method: 'post',
        data
    })
}

/** =================== None auth required APIs ====================== */

/**
 * @dev User function( get recommended NFTs )
 * @param { network } network 
 * @returns { objectId, sorted, refObjectId, tokenAddress, tokenId, tokenName, tokenImage, price, auctionPrice, fixedPrice, createdAt, updatedAt }
 */
export function userGetRecommendNFTs(network) {
    return authRequest({
        url: `/${network}/nft/recommends/list`,
        method: 'get',
    })
}

/**
 * @dev User function( get recommended Collections )
 * @param { network } network 
 * @returns { objectId, sorted, refObjectId, collectionTitle, collectionAddress, collectionDescription, createdAt, updatedAt }
 */
export function userGetRecommendCollections(network) {
    return authRequest({
        url: `/${network}/collections/recommends/list`,
        method: 'get',
    })
}

/**
 * @dev User function( register NFT )
 * @param { [tokenName,tokenAddress,tokenImage,ownerOf,tokenUri,blockNumber,tokenId,minter,metadata,tokenHash] } data 
 * @param { network } network 
 * @returns { true }
 */
export function userRegisterNFT(data, network) {
    return authRequest({
        url: `/${network}/user/nft/register`,
        method: 'post',
        data
    })
}



/**
 * @dev User Favorite function( Favorite NFT )
 * @param { tokenAddress, tokenId } data 
 * @param { network } network 
 * @returns { code, message, data}
 */

 export function userFavoriteNFT(data, network) {
    return authRequest({
        url: `/${network}/nft/favorite`,
        method: 'post',
        data
    })
}



/**
 * @dev Favorite Collection function( Favorite NFT )
 * @param { collectionId } data 
 * @param { network } network 
 * @returns { code, message, data}
 */

 export function favoriteCollection(data, network) {
    return authRequest({
        url: `/${network}/collections/favorite`,
        method: 'post',
        data
    })
}


/**
 * @dev User Follower function( Favorite NFT )
 * @param { toAddress } data 
 * @param { network } network 
 * @returns { code, message, data}
 */

 export function userFollow(data, network) {
    return authRequest({
        url: `/${network}/user/follower`,
        method: 'post',
        data
    })
}



/**
 * @dev checkAlreadyAuctionBid
 * @param {tokenAddress, tokenId} data
 * @param { network} network 
 * @returns { code, message, data}
 */
 export function checkAlreadyAuctionBid(data, network) {
    return authRequest({
        url: `/${network}/nft/checkAlreadyAuctionBid`,
        method: 'get',
        data
    })

}

/**
 * @dev checkAlreadyAuctionBid
 * @param {tokenAddress, tokenId} data
 * @param { network} network 
 * @returns { code, message, data}
 */
 export function checkAlreadyOffered(data, network) {
    return authRequest({
        url: `/${network}/nft/checkAlreadyOffered`,
        method: 'get',
        data
    })
}


