import {
    Table,
    Card,
} from "antd";

import { styles } from "../styles";
import { columns } from "../utils";

const UserListTable = ({ users }) => {
    return (

        <div className="top-user-table">
            <Card
                headStyle={styles.cardHeader}
                style={styles.card}
                title="Top users"
                bordered={false}
            >
                <Table
                    dataSource={users}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 100 }}
                />
            </Card>
        </div>
    )

}

export default UserListTable;