import React from "react";

const HeaderContext = React.createContext({
    currentChain: {},
    showWeb3AuthAgreeModal: false, 
    hasUserClickTandC: false, 
    isAuthModalVisible: false,
    isNetworkModalVisible: false,
    showWrongNetworkModal: false,
    isMobileMenuVisible: false,
    prevChain: null,
    gotoDiffURL: false,
    isProviderError: false,

    setCurrentChain: () => {},
    setShowWeb3AuthAgreeModal: () => {},
    setHasUserClickTandC: () => {},
    setIsAuthModalVisible: () => {},
    setIsNetworkModalVisible: () => {},
    setShowWrongNetworkModal: () => {},
    setIsMobileMenuVisible: () => {},
    setPrevChain: () => {},
    setGotoDiffURL: () => {},
    setIsProviderError: () => {},

    enableWeb3First: () => {},
    web3Authenticate: ()=> {}
});

export default HeaderContext;
