import {
    Modal, 
    Button,
    Col,
    Row
} from "antd";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useChain } from "react-moralis";
import { useHeader } from "providers/HeaderProvider/HeaderProvider";
import { setNetwork } from "slices/networkSlice";

import { chainData } from "../chainData";
import { checkTargetAndCurrentHostname, openExternalLink, saveDefaultConfig } from "../utils";

import BscModelBg from "assets/images/new/bsc_model_bg.png";
import chainCheckedIcon from "assets/images/new/header/chain_checked.png";
import { app_env } from "helpers/networks";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const NetworkListModalComponent = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { switchNetwork, chain } = useChain();
    const dispatch = useDispatch();

    const { 
        prevChain,
        currentChain, 
        isNetworkModalVisible, 
        showWrongNetworkModal,
        setCurrentChain,
        setGotoDiffURL,
        setPrevChain,
        setIsNetworkModalVisible,
        // connectWallet
    } = useHeader();

    // Select network from list
    const handleNetworkChange = (data) => {
        if (data.disabled) return;
        // console.log("Handle:", data);
        setCurrentChain(data);
    };

    // When user click confirm button after select desired network.
    const SwitchChosenNetwork = async () => {
        if (currentChain && !currentChain.disabled) {
            if (chain) {
                const { chainId } = chain;

                if (chainId !== currentChain.chainId) {
                    await switchNetwork(currentChain.chainId);
                    console.log("switchnetwork")
                    // const connectorId =  window.localStorage.getItem("connectorId");
                    // if (connectorId) {
                    //     await connectWallet(connectorId);
                    // }
                }
                if (checkTargetAndCurrentHostname(currentChain.site_url) !== "same") {
                    setGotoDiffURL(true);
                } 
            }
            // In case that wallect is not connected on site or has no wallet on browser
            else {
                if (checkTargetAndCurrentHostname(currentChain.site_url) !== "same") {
                    openExternalLink(currentChain.site_url);
                    return;
                } 

                saveDefaultConfig(currentChain.chainId);
                dispatch(setNetwork(currentChain.chainId));
            }
        }
        if (pathname.includes("/nftDetail") || pathname.includes("/viewCollection")) {
            history.push("/");
        }

        setPrevChain(null);
        setIsNetworkModalVisible(false);
    };

    return (
        <Modal
            open={isNetworkModalVisible || showWrongNetworkModal}
            footer={false}
            closable={false}
            centered={true}
            maskClosable={true}
            onCancel={() => {
                if (prevChain) {
                  setCurrentChain(prevChain);
                }
                setPrevChain(null);
                setIsNetworkModalVisible(false)
            }}
            className="bsc-model"
            width={361}
        >
            <div
                className="form-group"
                style={{ backgroundImage: `url(${BscModelBg})` }}
            >
            {/* Wrong Network */}
            {showWrongNetworkModal && (
                <div style={{ margin: "15px", color: "#fb7272", fontSize: "16px" }}>
                {t("chooseNetwork")}
                </div>
            )}
            {/* Network List */}
            <Row className="">
                {chainData && chainData.map((item, index) => {
                    if (item.visible === false) {
                        return <div key={index}></div>;
                    }
                    let disabledNetwork = false;
                    // If network is connected with wrong network.
                    // we will apply in case of mainnet only, because for production, 
                    // we don't have to show testnet to customers.
                    if (item.testnet && app_env.includes("main")) {
                        if(!showWrongNetworkModal || item.chainId !== chain.chainId) {
                            return <div key={index}></div>
                        } else {
                            disabledNetwork = true;
                        }
                    }
                    return (
                        <Col xl={24} lg={24} md={24} sm={24} key={index}>
                            <Row
                                className={{
                                    mainnet: true,
                                    "mainnet-curr": currentChain.value === item.value,
                                    disabled: item.disabled || disabledNetwork,
                                }}
                                onClick={() => handleNetworkChange(item)}
                                align="middle"
                                justify="space-between"
                            >
                                <Row align="middle">
                                    <div className="mainnet-item">
                                        <img
                                        src={item.logoUrl}
                                        style={{ width: "14px" }}
                                        alt=""
                                        />
                                    </div>
                                    {item.label}

                                    {currentChain.value === item.value && (
                                        <img
                                            className="anticon-check"
                                            src={chainCheckedIcon}
                                            alt="NichoNFT check icon"
                                        />
                                    )}
                                </Row>
                            </Row>
                        </Col>
                    )
                })}
            </Row>
            {/* Confirm Button */}
            <Row justify="center" style={{ margin: "16px 0 15px" }}>
                <Button
                    className="theme-tiff-button"
                    type="primary"
                    onClick={() => SwitchChosenNetwork()}
                    style={{ marginRight: "15px" }}
                >
                    {t("confirm")}
                </Button>
            </Row>
            </div>
        </Modal>
    )
}

export default NetworkListModalComponent;