import { useTranslation } from "react-i18next";
import buyButtonImg from "assets/images/new/mintToEarn/buy_button.png";

const GetNicho = () => {
  const { t } = useTranslation();
    return (
        <div className="earn-bot-footer">
            <p>{t("NICHOHelpsYou")}</p>
            <h5>{t("getTheNICHO")}</h5>
            <a
                href="https://bscscan.com/token/0x52904d8bB07e72541C854793242D51128043d527"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                <img src={buyButtonImg} alt="Nicho ERC20 Token" />{" "}
            </a>
            <div className="fine-desc">
                <p>{t("theRatesShown")}</p>
                <p>{t("actualRates")}</p>
            </div>
        </div>
    )
}

export default GetNicho;