import request from "./auth_request";

//
export function getOpenaiImages(data) {
  return request({
    url: `/openai/images/create`,
    method: "post",
    data,
  });
}

export function getAiImagesDetails(data) {
  return request({
    url: `/ai/images/generate`,
    method: "post",
    data,
  });
}

export function getAiImages(data) {
  return request({
    url: `/ai/images/get`,
    method: "post",
    data,
  });
}

export function getAiModels(data) {
  return request({
    url: `/ai/images/models`,
    method: "post",
    data,
  });
}
