import {
    Modal, 
    message,
    Typography
} from "antd";
import { useMoralis } from "react-moralis";
import { connectors } from "../config";
import { useHeader } from "providers/HeaderProvider/HeaderProvider";
import { setAuthToken } from "api/auth";
import { getSignature } from "../utils";
import { useState } from "react";

const { Text } = Typography;

const styles = {
    account: {
        height: "42px",
        padding: "0 15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        borderRadius: "12px",
        backgroundColor: "rgb(244, 244, 244)",
        cursor: "pointer",
    },
    text: {
        color: "#21BF96",
    },
    connector: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "27px 0px 4px 0",
        cursor: "pointer",
    },
    icon: {
        alignSelf: "center",
        fill: "rgb(40, 13, 95)",
        flexShrink: "0",
        marginBottom: "8px",
        height: "30px",
    },
};

const ConnectWalletModal = () => {
    const {
        logout,
        authenticate,
    } = useMoralis();
    const { 
        isAuthModalVisible,
        setIsProviderError,
        setIsAuthModalVisible,
        setShowWeb3AuthAgreeModal,
        enableWeb3First,
    } = useHeader();

    const [connecting, setConnecting] = useState("");

    const connectWallet = async (connectorId) => {

        try {
            if (connectorId === "web3auth") {
                setShowWeb3AuthAgreeModal(true);
            } else if (connectorId === "walletconnect") {
                const {message, account, chainId} = await enableWeb3First(connectorId);
                const user = await authenticate({
                    provider: connectorId,
                    signingMessage: message,
                    mobileLinks: [
                        "rainbow",
                        "metamask",
                        "argent",
                        "trust",
                        "imtoken",
                        "pillar",
                    ],
                });

                const signature = getSignature(user);
                await setAuthToken(chainId, account, signature, logout);
            } else {
                const {message, account, chainId} = await enableWeb3First(connectorId);
                const user = await authenticate({
                    provider: connectorId,
                    signingMessage: message,
                    throwOnError: true,
                });
                
                const signature = getSignature(user);
                await setAuthToken(chainId, account, signature, logout);
            }

            window.localStorage.setItem("connectorId", connectorId);
            setIsAuthModalVisible(false);
        } catch (e) {
            // console.error(JSON.stringify(e));
            if (e?.message === "Non ethereum enabled browser") {
                setIsProviderError(true);
                setIsAuthModalVisible(false);
            } else {
                console.log(e)
                message.error("SignIn Failed. Try it again");
            }
        } finally {
            setConnecting("");
        }
    }

    return (
        <Modal
            open={isAuthModalVisible}
            footer={null}
            onCancel={() => setIsAuthModalVisible(false)}
            bodyStyle={{
                padding: "0",
                fontSize: "17px",
                fontWeight: "500",
            }}
            className="wallet-modal"
        >
            <div
                className="connectors"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3,133px)",
                }}
            >
                {connectors.map(({ title, icon, connectorId }, key) => (
                    <div
                        style={styles.connector}
                        key={key}
                        onClick={() => {
                            if (connecting !== "") {
                                return;
                            }
                            setConnecting(title);
                            connectWallet(connectorId);
                        }}
                    >
                        <img src={icon} alt={title} style={styles.icon} />
                        <Text
                            style={{
                                fontSize: "14px",
                                color: "#fff",
                                fontWeight: "bold",
                            }}
                            color="#fff"
                        >
                            { connecting === title ? "Connecting ..." : title}
                        </Text>
                    </div>
                ))}
            </div>
        </Modal>
    )
}

export default ConnectWalletModal;