export const getImageTaskId = async (imageConfigs) => {
  let generatedImageData = "";
  const url = `https://nichonft.com/api/v4/image/generate`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(imageConfigs),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.ok) {
    const data = await response.json();
    if (data.code === 0) {
      generatedImageData = data.data.taskId;
    } else if (data.code === 4002 || data.code === 4101) {
      generatedImageData = "18+";
    }
  }
  return generatedImageData;
};

export const getV4GeneratedImage = async (taskId) => {
  let generatedImageData = [];
  const url = `https://nichonft.com/api/v4/image/get`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(taskId),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.ok) {
    const data = await response.json();
    if (data.code === 0) {
      generatedImageData = data.data;
    } else if (data.code === 4002 || data.code === 4101) {
      generatedImageData = [{ seed: 0, imageUrl: "18+", smallImageUrl: "" }];
    }
  }
  return generatedImageData;
};
