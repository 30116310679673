import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useMoralis } from "react-moralis";
import { useStake } from "providers/StakeProvider";
import { truncateHash } from "helpers/Utils";

import {
  nichoTokenABI,
  nichoFarmABI,
} from "contracts/constants";

import StakeModal from "./components/stake-modal";
import WithdrawModal from "./components/withdraw-modal";
import GetNicho from "./components/get-nicho";
import StakeBanner from "./components/banner";
import StakeOverview from "./components/overview";

import { BlocksPerMinute, FlexRewardPercent, LockedRewardPercent, TOKEN_DECIMAL, getNumberSuffix } from "./utils";
import UserListTable from "./components/user-list";
import "./Staking.scss";
import { getDateFromTime } from "helpers/Utils";
import { getUTCTimeStamp } from "helpers/Utils";
import getStakers from "api/getStakers";

// import UnderConstruction from "./UnderConstruction/UnderConstruction";


const Staking = () => {
  const { 
    nichoTokenContract: nichoTokenContractAddress, 
    nichoFarmContract: nichoFarmContractAddress 
  } = useSelector((state) => state.network.contracts);
  const { network } = useSelector((state) => state.network);

  // UseContext
  const { 
    refresh,
    setLockAPR,
    setFlexAPR,
    setUserData,
    setNichoBalance,
    setTotalVolumeLocked, 
    setTotalFlexTokenAmount,
    setTotalLockedTokenAmount
  } = useStake();

  const { user, Moralis, isWeb3Enabled, isInitialized } = useMoralis();
  const [users, setUsers] = useState([]);
  const [apr, setAPR] = useState("0");
  const [signer, setSigner] = useState();

  // Get signer
  useEffect(() => {
    const getSigner = async() =>{
      let web3Provider;
      let tmpSigner;
      if (!isWeb3Enabled) {
          web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider
          tmpSigner = web3Provider.getSigner();
          setSigner(tmpSigner);
      }
    }

    getSigner();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isWeb3Enabled])

  // Fetch contract data
  useEffect(() => {
    // Flexible staking data
    const fetchFlexibleStakingData = async (nichoFarmContract, rewardPerBlock) => {
      const stakedFlexTokenWeiSupply = await nichoFarmContract.stakedFlexTokenSupply();
      const stakedFlexTokenSupply = parseFloat(Moralis.Units.FromWei(
        stakedFlexTokenWeiSupply.toString(),
        TOKEN_DECIMAL
      ));

      if (stakedFlexTokenSupply <= 0) {
        return { 
          stakedFlexTokenSupply: 0, 
          lockAPY: 0, 
          lockAPR: 0
        }
      }

      const rewardPercentPerDay = (parseFloat(rewardPerBlock) * FlexRewardPercent * BlocksPerMinute * 60 * 24) / stakedFlexTokenSupply;
      const flexAPY = Math.pow(
        1.0 + rewardPercentPerDay,
        365
      );

      const flexAPR = (rewardPercentPerDay * 365 +1) * 100;
      return { 
        stakedFlexTokenSupply, 
        flexAPY: flexAPY, 
        flexAPR: flexAPR
      }
    }

    // Locked staking data
    const fetchLockedStakingData = async (nichoFarmContract, rewardPerBlock) => {
      const stakedLockTokenWeiSupply = await nichoFarmContract.stakedLockTokenSupply();
      const stakedLockTokenSupply = parseFloat(Moralis.Units.FromWei(
        stakedLockTokenWeiSupply.toString(),
        TOKEN_DECIMAL
      ));

      if (stakedLockTokenSupply <= 0) {
        return { 
          stakedLockTokenSupply: 0, 
          lockAPY: 0, 
          lockAPR: 0
        }
      }

      const rewardPercentPerDay = (parseFloat(rewardPerBlock) * LockedRewardPercent * BlocksPerMinute * 60 * 24) / stakedLockTokenSupply;

      const lockAPY = Math.pow(
        1.0 + rewardPercentPerDay,
        365
      );
      const lockAPR = (rewardPercentPerDay * 365 +1) * 100;

      return { 
        stakedLockTokenSupply, 
        lockAPY: lockAPY, 
        lockAPR: lockAPR
      }
    }

    // Get users data
    const getStakerListData = async (totalDepositAmount) => {
      const result = await getStakers(network);
      const { records, total } = result;
      let userLength = total;

      const userInfoList = [];

      for (let i = 0; i < userLength; i++) {
        const { userAddress, rankNo, totalAmount } = records[i];
        userInfoList.push({
          key: `${rankNo}`,
          no: `${rankNo}`,
          userAddress: `${truncateHash(userAddress, 8, 6)}`,
          stakedValue: `${totalAmount} Nicho`,
          percent: `${
            ((totalAmount * 100) / totalDepositAmount).toFixed(2)
          } %`,
        });
      }
      setUsers(userInfoList);
    }

    // Get my data
    const getMyInfo = async (
      nichoFarmContract, nichoTokenContract, signerAddr
    ) => {

      const tokenBalance = await nichoTokenContract.balanceOf(signerAddr);
      setNichoBalance(Moralis.Units.FromWei(tokenBalance.toString(), TOKEN_DECIMAL));

      const userInfo = await nichoFarmContract.userInfo(signerAddr);
      const { lockDays: lockDaysInSeconds, lockedDate, amount } = userInfo;

      const lockDays = parseInt(lockDaysInSeconds.toString()) / 86400;

      const stakedAmount = Moralis.Units.FromWei(amount.toString(), TOKEN_DECIMAL);
      const unlockDateTimestamp = parseInt(lockedDate.toString()) + parseInt(lockDays.toString()) * 86400;
      let earnedAmount = 0;
      if (lockDays.toString() !== "0") {
        const userRewardAmount = await nichoFarmContract.getLockRewardAmount(signerAddr);
        earnedAmount = Moralis.Units.FromWei(userRewardAmount.toString(), TOKEN_DECIMAL);
      } else if (amount.toString() !== "0") {
        const userRewardAmount = await nichoFarmContract.getFlexRewardAmount(signerAddr);
        earnedAmount = Moralis.Units.FromWei(userRewardAmount.toString(), TOKEN_DECIMAL);
      } else {
        earnedAmount = 0;
      }
      console.log(parseInt(unlockDateTimestamp), parseInt(getUTCTimeStamp(0)))
      setUserData({
        stakedAmount,
        earnedAmount,
        lockDays,
        lockedDate: getDateFromTime(lockedDate.toString()),
        unlockDate: getDateFromTime(unlockDateTimestamp),
        isUnlocked: parseInt(unlockDateTimestamp) <= parseInt(getUTCTimeStamp(0))
      })
    }

    // Fetch data
    const fetchContractData = async () => {
      const ethers = Moralis.web3Library;

      const signerAddr = await signer.getAddress();
      const nichoFarmContract = new ethers.Contract(
        nichoFarmContractAddress,
        nichoFarmABI,
        signer
      );
      const nichoTokenContract = new ethers.Contract(
        nichoTokenContractAddress,
        nichoTokenABI,
        signer
      );

      const rewardWeiPerBlock = await nichoFarmContract.rewardPerBlock();
      const rewardPerBlock = Moralis.Units.FromWei(rewardWeiPerBlock.toString(), TOKEN_DECIMAL);

      const {stakedLockTokenSupply, lockAPR } = await fetchLockedStakingData(nichoFarmContract, rewardPerBlock);
      const {stakedFlexTokenSupply, flexAPR } = await fetchFlexibleStakingData(nichoFarmContract, rewardPerBlock);
      
      setTotalFlexTokenAmount(stakedFlexTokenSupply);
      setTotalLockedTokenAmount(stakedLockTokenSupply);
      
      setFlexAPR(flexAPR);
      setLockAPR(lockAPR);

      setAPR(getNumberSuffix(Math.max(lockAPR, flexAPR), 2));
      
      // Total Volume Locked
      const tvl = stakedLockTokenSupply + stakedFlexTokenSupply;
      setTotalVolumeLocked(tvl);

      // User List Data
      await getStakerListData(tvl);

      // Get my data
      if (isInitialized && user) {
        await getMyInfo(
          nichoFarmContract, nichoTokenContract, signerAddr
        );
      }
    };
    const getContractData = () => {
      signer && fetchContractData();
    };
    getContractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    isInitialized,
    signer,
    refresh
  ]);

  return (
    <div className="staking-page center-page2">
      {/* Modals */}
      <StakeModal />
      <WithdrawModal />

      <StakeBanner apr={apr} />
      <StakeOverview />
      <UserListTable users={users} />

      <GetNicho />

      {/* <UnderConstruction /> */}
    </div>
  );
};

export default Staking;
