import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useMoralis } from "react-moralis";
import { Dropdown } from "antd";
import { useTranslation } from 'react-i18next';
import { TopMenu } from "../menuData";

const UserAvatarMenu = () => {
    const { t } = useTranslation();

    const {
        isAuthenticated,
        isInitialized,
        user,
        logout,
    } = useMoralis();

    const [avatarImage, setAvatarImage] = useState("images/avatar.png");

    // Monitor user data changes.
    useEffect(() => {
        if (!isInitialized || !isAuthenticated || !user) return;

        const avatar = user.get("avatar");
        if (avatar === undefined) {
            return;
        }
        setAvatarImage(avatar);

    }, [user, isInitialized, isAuthenticated]);

    return (
        <Dropdown menu={{ items: TopMenu (t, logout) }} overlayClassName="user-dropdown" >
            <div style={{ cursor: 'pointer' }}>
                <img src={avatarImage} className="user-avatar" alt="Nicho User Avatar" />
                <DownOutlined />
            </div>
        </Dropdown>
    );
}

export default UserAvatarMenu;