import React, { useEffect, useState } from "react";
import ShirtModel from "./components/models/ShirtModel";
import "./Merchant.scss";
import { Button, Col, Modal, Row, Select, Tooltip, Typography } from "antd";
import MugModel from "./components/models/MugModel";
import CapModel from "./components/models/CapModel";
import BagModel from "./components/models/BagModel";
import SkateboardModel from "./components/models/SkateboardModel";
import FrameModel from "./components/models/FrameModel";
import {
  bagIcon,
  capIcon,
  frameIcon,
  mugIcon,
  shirtIcon,
  skateboardIcon,
  cupIcon,
  coffeeCupIcon,
} from "./assets/icons";
import { useTexture } from "@react-three/drei";
import { useMyNft } from "./hooks/useMyNft";
import { useMoralis } from "react-moralis";
import { useSelector } from "react-redux";
import CupModel from "./components/models/CupModel";
import CoffeeCupModel from "./components/models/CoffeeCup";
import { CirclePicker } from "react-color";
import { productsConfigs } from "./constants/productsConfigs";
import PaymentModal from "./components/PaymentModal/PaymentModal";

const modelOptions = [
  {
    value: "shirt",
    label: (
      <>
        <img src={shirtIcon} width={32} height={32} alt="shirt" />
        <span style={{ marginLeft: "1rem" }}>SHIRT</span>
      </>
    ),
  },
  {
    value: "coffeeCup",
    label: (
      <>
        <img src={coffeeCupIcon} width={32} height={32} alt="coffeeCup" />
        <span style={{ marginLeft: "1rem" }}>COFFEE CUP</span>
      </>
    ),
  },
  {
    value: "cup",
    label: (
      <>
        <img src={cupIcon} width={32} height={32} alt="cup" />
        <span style={{ marginLeft: "1rem" }}>CUP</span>
      </>
    ),
  },
  {
    value: "mug",
    label: (
      <>
        <img src={mugIcon} width={32} height={32} alt="mug" />
        <span style={{ marginLeft: "1rem" }}>MUG</span>
      </>
    ),
  },
  {
    value: "cap",
    label: (
      <>
        <img src={capIcon} width={32} height={32} alt="cap" />
        <span style={{ marginLeft: "1rem" }}>CAP</span>
      </>
    ),
  },
  {
    value: "bag",
    label: (
      <>
        <img src={bagIcon} width={32} height={32} alt="bag" />
        <span style={{ marginLeft: "1rem" }}>BAG</span>
      </>
    ),
  },
  {
    value: "skateboard",
    label: (
      <>
        <img src={skateboardIcon} width={32} height={32} alt="skateboard" />
        <span style={{ marginLeft: "1rem" }}>SKATEBOARD</span>
      </>
    ),
  },
  {
    value: "frame",
    label: (
      <>
        <img src={frameIcon} width={32} height={32} alt="frame" />
        <span style={{ marginLeft: "1rem" }}>FRAME</span>
      </>
    ),
  },
];

const Merchant = () => {
  const DEFAULT_PRODUCT = "shirt";
  // const [color, setColor] = useColor("hex", "#FFBF00");
  const [color, setColor] = useState(
    productsConfigs[DEFAULT_PRODUCT].avaliableColors[0]
  );
  const [avaliableColor, setAvaliableColor] = useState(
    productsConfigs[DEFAULT_PRODUCT].avaliableColors
  );
  const [selectedProduct, setSelectedProduct] = useState(DEFAULT_PRODUCT);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [selectedNft, setSelectedNft] = useState();
  const [myNftOptions, setMyNftOptions] = useState([]);
  const [myNft, setMyNft] = useState([]);
  const { items } = useMyNft();
  const { network } = useSelector((state) => state.network);
  const { user } = useMoralis();

  // console.log("Choosen color ", color.hex);

  const filterProducts = (product) => {
    const modelConfigs = {
      selectedNft,
      color,
    };

    switch (product) {
      case "coffeeCup":
        return <CoffeeCupModel {...modelConfigs} />;
      case "cup":
        return <CupModel {...modelConfigs} />;
      case "mug":
        return <MugModel {...modelConfigs} />;
      case "cap":
        return <CapModel {...modelConfigs} />;
      case "bag":
        return <BagModel {...modelConfigs} />;
      case "skateboard":
        return <SkateboardModel {...modelConfigs} />;
      case "frame":
        return <FrameModel {...modelConfigs} />;
      default:
        return <ShirtModel {...modelConfigs} />;
    }
  };
  // console.log(network);
  useEffect(() => {
    if (items) {
      let tempItemsArr = [];
      let tempNftArr = [];
      // eslint-disable-next-line
      items.map((item, index) => {
        tempItemsArr.push({
          value: index,
          label: (
            <>
              <img
                src={item.tokenImage}
                alt={item.tokenName}
                width={32}
                height={32}
              />
              <span style={{ marginLeft: "1rem" }}>{item.tokenName}</span>
            </>
          ),
        });
        tempNftArr.push(item.tokenImage);
      });
      setMyNftOptions(tempItemsArr);
      setMyNft(tempNftArr);
    }
  }, [items]);

  useEffect(() => {
    if (myNft.length !== 0) {
      myNft.forEach(useTexture.preload);
    }
  }, [myNft]);

  useEffect(() => {
    setMyNft([]);
    setSelectedNft();
  }, [network]);

  const paymentModalConfigs = {
    setIsOpenPaymentModal,
    sellingPrice: productsConfigs[selectedProduct]?.sellingPrice,
    selectedProduct,
    color,
    selectedNft,
  };

  return (
    <>
      <Modal
        centered
        open={isOpenPaymentModal}
        footer={false}
        closable={false}
        width={400}
      >
        <PaymentModal {...paymentModalConfigs} />
      </Modal>
      <div className="center-page">
        <div className="merchant-wrapper">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={16}
              xxl={16}
              className="merchant-content-left"
            >
              {filterProducts(selectedProduct)}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              xxl={8}
              className="merchant-content-right"
            >
              <div>
                <Typography.Paragraph className="merchant-content-right-title merchant-title-magic-text">
                  Nicho
                </Typography.Paragraph>
                <Typography.Paragraph className="merchant-content-right-title merchant-title-magic-text">
                  Merchant
                </Typography.Paragraph>
              </div>
              <div>
                <Typography.Paragraph className="merchant-content-right-label">
                  Select Products
                </Typography.Paragraph>
                <Select
                  style={{ width: "330px" }}
                  options={modelOptions}
                  defaultValue={"shirt"}
                  onChange={(value) => {
                    setSelectedProduct(value);
                    setAvaliableColor(productsConfigs[value]?.avaliableColors);
                    setColor(productsConfigs[value]?.avaliableColors[0]);
                  }}
                />
              </div>
              <div className="merchant-section-margin">
                <Typography.Paragraph className="merchant-content-right-label">
                  Select Your NFT
                </Typography.Paragraph>
                {!items || items.length === 0 ? (
                  <Tooltip
                    title={
                      !user
                        ? "Please connect wallet"
                        : "You don't have any NFTs"
                    }
                  >
                    <Select style={{ width: "330px" }} disabled />
                  </Tooltip>
                ) : (
                  <Select
                    style={{ width: "330px" }}
                    options={myNftOptions}
                    onChange={(value) => setSelectedNft(myNft[value])}
                  />
                )}
              </div>
              <div
                className="merchant-section-margin"
                style={{ width: "330px" }}
              >
                {productsConfigs[selectedProduct]?.avaliableColors.length !==
                0 ? (
                  <>
                    <Typography.Paragraph
                      className="merchant-content-right-label"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      Select Color
                    </Typography.Paragraph>
                    <CirclePicker
                      circleSize={32}
                      width="330px"
                      onChange={(color) => setColor(color.hex)}
                      colors={avaliableColor}
                    />
                  </>
                ) : null}
                {/* <ColorPicker
                width={330}
                height={100}
                color={color}
                onChange={setColor}
                hideHSV
                hideRGB
                hideHEX
                dark
              /> */}
              </div>
              <div className="merchant-section-margin">
                {/* {!user ? (
                  <Tooltip title="Please connect wallet">
                    <Button type="primary" style={{ width: "330px" }} disabled>
                      Buy
                    </Button>
                  </Tooltip>
                ) : (
                  <Button type="primary" style={{ width: "330px" }}>
                    Buy
                  </Button>
                )} */}
                <Button
                  type="primary"
                  style={{ width: "330px" }}
                  onClick={() => setIsOpenPaymentModal(true)}
                >
                  Buy{" "}
                  {`($${productsConfigs[selectedProduct]?.sellingPrice} AUD)`}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Merchant;
