import React from "react";
import "./PaymentModal.scss";
import { Button, Typography, message } from "antd";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { send } from "@emailjs/browser";

const PaymentModal = ({
  setIsOpenPaymentModal,
  sellingPrice,
  selectedProduct,
  color,
  selectedNft,
}) => {
  const DEFAULT_CURRENCY = "AUD";
  // email service, remember to hide in .env file
  const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const EMAIL_PUBLIC_KEY = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
  const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  return (
    <div className="payment-modal-container">
      <Typography.Paragraph className="payment-modal-title">
        Payment
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: "1rem" }}>
        <span className="payment-modal-price">${sellingPrice} </span>
        <span className="payment-modal-currency">AUD</span>
      </Typography.Paragraph>
      <div className="payment-modal-paypal-button">
        {/* This is sandbox account, remember to change the client id */}
        <PayPalScriptProvider
          options={{
            "client-id": PAYPAL_CLIENT_ID,
            currency: DEFAULT_CURRENCY,
          }}
        >
          <PayPalButtons
            forceReRender={[sellingPrice, selectedProduct]}
            style={{ layout: "vertical", label: "checkout" }}
            createOrder={(data, actions) =>
              actions.order.create({
                purchase_units: [
                  {
                    description: selectedProduct,
                    amount: {
                      currency_code: DEFAULT_CURRENCY,
                      value: sellingPrice,
                    },
                  },
                ],
              })
            }
            onApprove={(data, actions) =>
              actions.order.capture().then((details) => {
                message.success("Payment Successful");
                setIsOpenPaymentModal(false);
                // console.log(details);
                const emailTemplate = {
                  transactionId:
                    details.purchase_units[0].payments.captures[0]?.id,
                  customerName:
                    details.payer.name.given_name +
                    " " +
                    details.payer.name.surname,
                  customerEmail: details.payer.email_address,
                  selectedProduct: selectedProduct,
                  productColor: color || "NO COLOR",
                  nftLink: selectedNft || "NO NFT",
                };
                send(
                  EMAIL_SERVICE_ID,
                  EMAIL_TEMPLATE_ID,
                  emailTemplate,
                  EMAIL_PUBLIC_KEY
                ).then(
                  function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                  },
                  function (error) {
                    console.log("FAILED...", error);
                  }
                );
              })
            }
            onError={(err) => message.error("Payment failed")}
          />
        </PayPalScriptProvider>
      </div>
      <Button
        // style={{ marginTop: "1rem" }}
        onClick={() => setIsOpenPaymentModal(false)}
      >
        Close
      </Button>
    </div>
  );
};

export default PaymentModal;
