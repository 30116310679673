import React, { useState } from "react";
import StakeContext from "./context";

function StakeProvider({ children }) {
    // Modal controllers
    const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const [nichoBalance, setNichoBalance] = useState(null);
    const [totalVolumeLocked, setTotalVolumeLocked] = useState(0);
    const [userData, setUserData] = useState();
    const [flexAPR, setFlexAPR] = useState(0);
    const [lockAPR, setLockAPR] = useState(0);
    const [totalFlexTokenAmount, setTotalFlexTokenAmount] = useState(0);
    const [totalLockedTokenAmount, setTotalLockedTokenAmount] = useState(0);
    // 0 means locked staking, 1 means flexible staking
    const [stakingType, setStakingType] = useState(0);
    const [refresh, setRefresh] = useState(0);

    return (
        <StakeContext.Provider 
            value={{ 
                flexAPR, setFlexAPR,
                lockAPR, setLockAPR,
                refresh, setRefresh,
                userData, setUserData,
                stakingType, setStakingType,
                nichoBalance, setNichoBalance,
                totalVolumeLocked, setTotalVolumeLocked,
                isStakeModalVisible, setIsStakeModalVisible,
                totalFlexTokenAmount, setTotalFlexTokenAmount,
                totalLockedTokenAmount, setTotalLockedTokenAmount,
                isWithdrawModalVisible, setIsWithdrawModalVisible,
            }}
        >
            {children}
        </StakeContext.Provider>
    );
}

function useStake() {
    const context = React.useContext(StakeContext);
    if (context === undefined) {
        throw new Error("useStake must be used within a StakeProvider");
    }
    return context;
}

export { StakeProvider, useStake };
