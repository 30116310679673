import { createSlice } from "@reduxjs/toolkit";
import {
  getChainById,
  getCurrencyIcon,
  getNativeByChain,
  getPricePair,
  getNetwork,
  getAllContracts,
  getMoralisAppId,
  getAiDrawPrice,
  getExplorer,
} from "helpers/networks";
import BNBIcon from "assets/images/new/header/bnb.png";

/// BNB chain is a default.
const initialState = {
  chainId: null,
  currencySymbol: null,
  chainWarnAlert: false,
  currencyIcon: BNBIcon,
  aiDrawPrice: 0.01,
  pricePair: null,
  network: null,
  moralisAppId: null,
  blockExplorerUrl: null,
  contracts: {
    /// main NichoNFT contract
    nichonftContract: null,
    marketplaceContract: null,
    auctionContract: null,
    collectionFactoryContract: null,
    rewardContract: null,
    /// ERC20 contract
    nichoTokenContract: null,
    nichoFarmContract: null,
    aiAgentContract: null,
  },
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setNetwork: (state, action) => {
      /// pass chain (0x1)
      if (action.payload) {
        const chainId = getChainById(action.payload);
        const currencySymbol = getNativeByChain(action.payload);
        const currencyIcon = getCurrencyIcon(action.payload);
        const pricePair = getPricePair(action.payload);
        const network = getNetwork(action.payload);
        const contracts = getAllContracts(action.payload);
        const moralisAppId = getMoralisAppId(action.payload);
        const aiDrawPrice = getAiDrawPrice(action.payload);
        const blockExplorerUrl = getExplorer(action.payload);

        if (chainId === null || currencySymbol === "NATIVE") {
          state.chainWarnAlert = true;
        } else {
          state.chainId = chainId;
          state.currencySymbol = currencySymbol;
          state.currencyIcon = currencyIcon;
          state.chainWarnAlert = false;
          state.pricePair = pricePair;
          state.network = network;
          state.contracts = contracts;
          state.moralisAppId = moralisAppId;
          state.aiDrawPrice = aiDrawPrice;
          state.blockExplorerUrl = blockExplorerUrl;
        }
      }
    },
  },
});

export const { setNetwork } = networkSlice.actions;
export default networkSlice.reducer;
