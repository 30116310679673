import { useState } from "react";
import {
    Button,
    Modal,
    Input,
    Typography
} from "antd";
import { useSelector } from "react-redux";
import { useStake } from "providers/StakeProvider";
import { useMoralis } from "react-moralis";
import { floatFixed } from "helpers/Utils";
import {
    nichoFarmABI,
} from "contracts/constants";
import { TOKEN_DECIMAL } from "../utils";

const { Title } = Typography;

const percents =[25, 50, 75, 100];

const FormItem = ({title, content}) => {
    return (
        <div className="stake-header">
            <span> { title }</span>
            <span style={{color: '#1da97c'}}>
                { content }
            </span>
        </div>
    )
}
const MyStakingResult = ({userData, withdrawInputAmount = 0}) => {
    const { lockAPR, flexAPR } = useStake();
    
    const {
        stakedAmount,
        earnedAmount,
        lockedDate,
        lockDays,
        isUnlocked,
        unlockDate
    } = userData;

    if (!userData) {
        return (
            <span>Loading</span>
        )
    }

    return (
        <div className="form-item">
            <FormItem 
                title="Staked Balance" 
                content={ `${stakedAmount} NICHO` } 
            />
            <FormItem 
                title="Reward Balance" 
                content={ `${earnedAmount} NICHO` } 
            />
            {
                lockDays > 0 && ( <FormItem 
                    title="Locked Date" 
                    content={ lockedDate } 
                />)
            }
            <FormItem 
                title="Lock Duration" 
                content={ lockDays > 0? `${lockDays} Days`: "Flexible"}
            />
            {
                lockDays > 0 && (<FormItem 
                    title={ isUnlocked? "Unlocked Date": "Unlock Date" }
                    content={ unlockDate }
                />)
            }
            <FormItem 
                title="APR" 
                content={ `${ lockDays > 0? lockAPR : flexAPR } %` }
            />

            {
                isUnlocked === true ? 
                (
                    <div 
                        className="stake-header" 
                        style={{
                            marginTop: "32px",
                            color: "#3fb5c2"
                        }}
                    >
                        <span>Withdraw Amount (+reward)</span>
                        <span>
                            { parseFloat(
                                lockDays > 0 ? stakedAmount: withdrawInputAmount
                            ) + parseFloat(earnedAmount) } NICHO
                        </span>
                    </div>
                ): 
                (
                    <div 
                        className="stake-header" 
                        style={{
                            marginTop: "32px",
                            color: "red"
                        }}
                    >
                        <span>Your token still locked. Please wait for being unlocked.</span>
                    </div>
                )

            }
        </div>
    )
}
const WithdrawModal = () => {
    const { userData, isWithdrawModalVisible, refresh, setRefresh, setIsWithdrawModalVisible } = useStake();
    const { nichoFarmContract: nichoFarmContractAddress } = useSelector((state) => state.network.contracts);

    const [waiting, setWaiting] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const { Moralis } = useMoralis();

    const withdrawAmountChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
        setWithdrawAmount(inputValue);
        }
    };

    const setWithdrawAmountWithPercent = (percent) => {
        const { stakedAmount } = userData;
        const inputValueFixed = floatFixed((stakedAmount * percent) / 100);
        setWithdrawAmount(inputValueFixed);
    };

    const withdrawNicho = async () => {
        try {
            const { stakedAmount, earnedAmount, lockDays } = userData;
            if (lockDays === 0) {
                if (parseFloat(withdrawAmount) > parseFloat(stakedAmount)) {
                    const modal = Modal.error({
                        title: "Error!",
                        content: "Withdraw amount should not be over than staked amount!",
                    });
            
                    setTimeout(() => {
                        modal.destroy();
                    }, 3 * 1000);
                    return;
                }
            }

            setWaiting(true);
        
            const ethers = Moralis.web3Library;
            const web3Provider = await Moralis.enableWeb3(); // Get ethers.js web3Provider
            const signer = web3Provider.getSigner();
        
            const nichoFarmContract = new ethers.Contract(
                nichoFarmContractAddress,
                nichoFarmABI,
                signer
            );
        
            const tx = await nichoFarmContract.withdraw(
                Moralis.Units.Token(Number(withdrawAmount).toFixed(TOKEN_DECIMAL).toString(), TOKEN_DECIMAL)
            );
            const txhash = await tx.wait();
            setRefresh(refresh+1);

            const withdrawedAmount = (lockDays > 0? parseFloat(stakedAmount): Number(withdrawAmount )) + parseFloat(earnedAmount);
            const modal = Modal.success({
                title: "Success!",
                content: <span>
                    { `${withdrawedAmount} NICHO has been transferred to your wallet, This is a transaction hash ${txhash.transactionHash}`}
                </span>,
            });


            setTimeout(() => {
                modal.destroy();
                setWithdrawAmount(0);
                setIsWithdrawModalVisible(false);
            }, 3 * 1000);

        } catch (err) {
            console.log(err);
            const modal = Modal.error({
                title: "Error!",
                content: err?.data.message,
            });
            setTimeout(() => {
                modal.destroy();
            }, 4 * 1000);
        } finally {
            setWaiting(false);
        }
    };

    return (
        <Modal
            open={isWithdrawModalVisible}
            centered
            footer={false}
            onCancel={() => setIsWithdrawModalVisible(false)}
        >
            <div className="form-group">
                <div className="form-title">
                    <Title level={3} type="secondary">
                        Withdraw Nicho
                    </Title>
                    {
                        userData && (
                            <div className="stake-option">
                                { userData.lockDays <= 0 ? "Flexible": (userData && userData.isUnlocked? "Unlocked": "Locked") }
                            </div>
                        )
                    }
                 </div>
                { userData && userData.lockDays === 0 && (<div className="form-item">
                    <div className="stake-header">
                        <div>Withdraw Amount</div>
                        <div>Staked Balance : {userData.stakedAmount}</div>
                    </div>
                    <div className="stake-content">
                        <div>
                        <Input
                            size="small"
                            className="ant-input"
                            value={withdrawAmount}
                            onChange={(e) => withdrawAmountChange(e)}
                        />
                        </div>
                        <div>NICHO</div>
                    </div>
                    <div className="percentage-btn-group">
                        {
                            percents.map((percent) => {
                                return (
                                    <span
                                        key={percent}
                                        className="percentage-button"
                                        onClick={() => setWithdrawAmountWithPercent(percent)}
                                    >
                                        {percent >= 100 ? "Max": `${percent}%`}
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>)}

                <MyStakingResult 
                    userData={userData} 
                    withdrawInputAmount={withdrawAmount}
                />

                <div className="bottom-btn-group">
                    <Button
                        type="primary"
                        size="small"
                        disabled={waiting}
                        onClick={(e) => setIsWithdrawModalVisible(false)}
                    >
                        {" "}
                        Cancel{" "}
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        disabled={waiting || (userData && userData.isUnlocked === false)}
                        loading={waiting}
                        onClick={withdrawNicho}
                    >
                        {" "}
                        Withdraw{" "}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default WithdrawModal;