import request from './request'
import authRequest from './auth_request'
// 
export function getNFTListApi(data, network) {
  return request({
    url: `/${network}/nft/getNFTList`,
    method: 'post',
    data
  })
}

// 
export function getTokenCollectionApi(data, network) {
  return request({
    url: `/${network}/token/getTokenCollections`,
    method: 'post',
    data
  })
}

export function getCreatedNFTsApi(data, network) {
  return authRequest({
    url: `/${network}/user/getCreatedNFTs`,
    method: 'post',
    data
  })
}

export function getUserRecommendNFTsApi(data, network) {
  return authRequest({
    url: `/${network}/user/getUserRecommendNFTs`,
    method: 'post',
    data
  })
}

export function getOwnedNFTsApi(data, network) {
  return request({
    url: `/${network}/user/getOwnedNFTs`,
    method: 'post',
    data
  })
}