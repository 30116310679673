import { useState, useEffect } from "react";
import {
    Space,
    Popover,
} from "antd";
import { useTranslation } from 'react-i18next';
import { TranslationOutlined } from "@ant-design/icons";

const LanguageSelector = ({headerRef}) => {
    const { i18n } = useTranslation();
    const [languageType, setLanguageType] = useState();

    // When different screen loads, screen should goes up.
    useEffect(() => {
        const lng = localStorage.getItem('languageType')
        setLanguageType(lng)
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('languageType', lng)
        setLanguageType(lng)
    };

    const languageContent = (
        <div className="lang-dropdown-wrap">
        <div className={`lang-item ${languageType === 'en' ? 'active' : ''}`} onClick={() => {changeLanguage('en')}}>English</div>
        <div className={`lang-item ${languageType === 'cn' ? 'active' : ''}`} onClick={() => {changeLanguage('cn')}}>中文</div>
        <div className={`lang-item ${languageType === 'es' ? 'active' : ''}`} onClick={() => {changeLanguage('es')}}>español</div>
        <div className={`lang-item ${languageType === 'ja' ? 'active' : ''}`} onClick={() => {changeLanguage('ja')}}>Japanese</div>
        </div>
    )
    return (
        <Popover overlayClassName="lang-popover" getPopupContainer={() => headerRef.current} content={languageContent}>
            <Space>
                <TranslationOutlined className="lang-icon"/>
            </Space>
        </Popover>
    )
}

export default LanguageSelector;