import React from "react";
import { Provider } from 'react-redux'
import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";
import { store } from './app/store'
import App from "./App";
import "locale/i18n";
import "./index.css";

/** Get your free Moralis Account https://moralis.io/ */

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const Application = () => {
  const isServerInfo = APP_ID && SERVER_URL  ? true : false;

  if (isServerInfo) {
    return (
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <MoralisDappProvider>
          <App isServerInfo />
        </MoralisDappProvider>
      </MoralisProvider>
    );
  }

  return <ErrorPage error="Something went wrong. Please check server configurations." />;
};

const ErrorPage = ({ error }) => {
  return (
    <div 
      style={{ 
        display: "flex", 
        height: "100vh", 
        justifyContent: "center", 
        alignItems: "center", 
        color: "white",
        fontSize: "24px"
      }}
    >
      {error}
    </div>
  );
}
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Application />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
