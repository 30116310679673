import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import cupGlb from "../../assets/models/cup.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const CupModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 0.6], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <Cup color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.5}
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2.3}
        maxPolarAngle={Math.PI / 4}
      />
    </Canvas>
  </div>
);

function Cup(props) {
  const { nodes, materials } = useGLTF(cupGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(
      materials["0.917647_0.917647_0.917647_0.000000_0.000000"].color,
      props.color,
      0.25,
      delta
    )
  );
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI, 0, 0]}>
        <mesh
          //   castShadow
          //   receiveShadow
          geometry={nodes.Object_2.geometry}
          material={materials["0.917647_0.917647_0.917647_0.000000_0.000000"]}
        >
          <Decal
            position={[0, -0.05, -0.07]}
            rotation={[0, Math.PI, Math.PI]}
            scale={0.08}
            map={texture}
            map-anisotropy={16}
          />
        </mesh>
      </group>
    </group>
  );
}

useGLTF.preload(cupGlb);
export default CupModel;
