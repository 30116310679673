import {
  Typography,
  Space,
  Image
} from "antd";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";
import { useSelector } from "react-redux";
import GalleryIcon from "assets/images/new/event/icons/gallery.png";
import BnbLight from "assets/images/new/event/icons/bnbLight.png";
import { styles } from "./styles";
import { useGetTokenPriceQuery } from "services/price";
import { getFilteredPrice } from "hooks/useGetCoinPrice";

const { Title, Paragraph } = Typography;

const TitleComponent = ({ imagePath, content }) => {
  return (
    <Space align="center" size={10}>
      <Image preview={false} width={30} height={30} src={imagePath} />
      <Title level={5}>{content}</Title>
    </Space>
  );
};

const Info = () => {
  const { pricePair, currencySymbol } = useSelector((state) => state.network);
  const { data: priceData, error, isLoading } = useGetTokenPriceQuery(pricePair);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div style={styles.info}>
      <Title level={2} style={styles.gulfFont2}>
        {t("theAustraliaCollection")}
      </Title>

      <Space
        align="center"
        size={isDesktopOrLaptop ? 50 : 10}
        style={styles.nameComp}
        direction={isDesktopOrLaptop ? "horizontal" : "vertical"}
      >
        <div
          style={{
            border: "1px solid #53f2d4",
            padding: "10px 80px",
            borderRadius: 10,
          }}
        >
          <span style={{ marginRight: 80 }}>
            <TitleComponent
              imagePath={GalleryIcon}
              content={`${t("totalSupply")}: 34 NFTs`}
            />
          </span>

          <TitleComponent
            imagePath={BnbLight}
            content={`${t("tradingCurrency")}:  ${currencySymbol} (${
              (error || isLoading || !priceData) ? "$0": getFilteredPrice(Number(priceData.data[0].price))
            })`}
          />
        </div>
      </Space>
      <Paragraph>
        {t("allArtworks")}{" "}
        <span style={{ color: "#f3ba2c" }}>{t("binanceSmartChain")}</span>
      </Paragraph>
    </div>
  );
};

export default Info;
