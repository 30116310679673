import { server_url } from "./config";
import axios from 'axios';
//
const getStakers = (network) => {
    const fetchPromise = function(resolve,reject) {
        const url = server_url + `/${network}/stake/rank`;
        const data = {
            "pageNo": 1,
            "pageSize": 10
        }
        axios.post(url, data)
        .then(function (response) {
            // handle success
            resolve(response.data.data);
        })
        .catch(function (error) {
            // handle error
            reject(error)
        })
    }
    return new Promise(fetchPromise);}

export default getStakers;