import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import coffeeCupGlb from "../../assets/models/coffeeCup.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const CoffeeCupModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 13], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <CoffeeCup color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.5}
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2.3}
        maxPolarAngle={Math.PI / 4}
      />
    </Canvas>
  </div>
);

function CoffeeCup(props) {
  const { nodes, materials } = useGLTF(coffeeCupGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(materials.Root.color, props.color, 0.25, delta)
  );
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 4.7]}>
        <group position={[4.08, 1.01, 5.9]} rotation={[-0.27, 0.6, 1.93]} />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_0.geometry}
          material={materials.Root}
        >
          <Decal
            position={[1, 0, 1.6]}
            rotation={[0, 1.55, 1.55]}
            scale={1.6}
            map={texture}
            map-anisotropy={16}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001_0.geometry}
          material={materials.Root}
        />
      </group>
    </group>
  );
}

useGLTF.preload(coffeeCupGlb);
export default CoffeeCupModel;
