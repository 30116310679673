import { getNetwork } from 'helpers/networks';
import request from './request'

// 
export function getAuthToken(data, network) {
  return request({
    url: `/${network}/auth/token`,
    method: 'post',
    data
  })
}

export async function setAuthToken (hexId, address, signature, logout) {
  try {
    // Connected wallet?
    if (address && signature) {
      const network = getNetwork(hexId);
      const requestData = {
          userAddress: address.toLowerCase(),
          signature: signature
      }
      const {token, expireTime} = await getAuthToken(requestData, network);
      localStorage.setItem('authExpireTime', expireTime) // 3 minutes from now
      localStorage.setItem("apiToken", token);
    }
  } catch (err) {
    logout();
  }
}