export const styles = {
    card: {
        boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
        // border: "1px solid #7774ff",
        border: "1px solid rgba(255, 255, 255, 0.16)",
        // border: "1px solid rgba(255, 255, 255, 0.16)",
        borderRadius: "0.5rem",
        background: "linear-gradient(rgba(119, 214, 255, 0.08), rgba(202, 169, 255, 0.08))",
        // background: "#161822",
        color: "white"
    },
    cardHeader: {
        color: "white",
        border: "1px solid rgba(255, 255, 255, 0.16)",
        // marginTop: '20px'
    },
    cardTag: {
        color: "white"
    },
    
    modalTitle: {
        color: 'black'
    },

    adminButton: {
        marginBottom: '20px'
    },

    colMarginTop: {
        marginTop: '20px',
        marginBottom: '20px'
    }
};
