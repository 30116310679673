import React from "react";
import {
  Decal,
  useGLTF,
  useTexture,
  Center,
  OrbitControls,
} from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { easing } from "maath";
import mugGlb from "../../assets/models/mug.glb";
import sampleImg from "../../assets/images/baseImg.png";
import "../../Merchant.scss";

const MugModel = ({ color, selectedNft }) => (
  <div className="merchant-model">
    <Canvas
      shadows
      camera={{ position: [0, 0, 0.6], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <ambientLight intensity={0.2} color="white" />
      <directionalLight color="white" position={[0, 0, -20]} intensity={1} />
      <directionalLight color="white" position={[0, 0, 20]} intensity={1} />
      <directionalLight color="white" position={[20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[-20, 0, 0]} intensity={1} />
      <directionalLight color="white" position={[0, 20, 0]} intensity={1} />
      <Center>
        <Mug color={color} selectedNft={selectedNft} />
      </Center>
      <OrbitControls
        autoRotate
        autoRotateSpeed={0.5}
        makeDefault
        enableZoom={false}
        minPolarAngle={Math.PI / 2.5}
        maxPolarAngle={Math.PI / 4}
      />
    </Canvas>
  </div>
);

function Mug(props) {
  const { nodes, materials } = useGLTF(mugGlb);
  const texture = useTexture(props.selectedNft || sampleImg);
  useFrame((state, delta) =>
    easing.dampC(materials["Material.001"].color, props.color, 0.25, delta)
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_2.geometry}
        material={materials["Material.001"]}
        rotation={[-Math.PI / 2, 0, -1.5]}
      >
        <Decal
          position={[0.08, 0, 0.08]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={0.1}
          map={texture}
          map-anisotropy={16}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload(mugGlb);
export default MugModel;
