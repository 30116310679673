import {
    Row,
    Col,
    Button,
    Divider,
    Space,
} from "antd";
import { useMoralis } from "react-moralis";
import CountUp from "react-countup";
import { useStake } from "providers/StakeProvider";
import { useTranslation } from "react-i18next";
import { FORMAT_DECIMAL, StakingTypeEnum } from "../utils";

const OverviewItem = ({ title, volume, apr}) => {
    return (
        <>
            <div className="txt">
                {title} Volume:
                <span className="sub-txt">
                    { ` ${Number(volume).toFixed(FORMAT_DECIMAL)} ` }
                    <span className="nicho-txt">NICHO</span>
                </span>
            </div>
            <div className="txt">
                {title} APR:
                <span className="sub-txt">
                    <span style={{ color: "#53f2d4"}}>{ ` ${Number(apr).toFixed(FORMAT_DECIMAL)} ` }</span>
                    <span className="nicho-txt">%</span>
                </span>
            </div>
        </>
    );
}
const GlobalOverview = () => {
    const { t } = useTranslation();
    const {flexAPR, lockAPR, totalVolumeLocked, totalFlexTokenAmount, totalLockedTokenAmount } = useStake();

    return (
        <div className="tab-card">
            <div className="card-header">{t("NICHOCompounder")}</div>
            <Divider className="card-header-divider" />
            <div className="card-content">
                <div className="card-content-background">
                    <div className="content-data">
                        <div className="txt">
                            Total Volume Locked:{" "}
                            <span className="sub-txt">
                                {" "}
                                <CountUp
                                    end={Number(totalVolumeLocked).toFixed(FORMAT_DECIMAL)}
                                    decimals={FORMAT_DECIMAL}
                                    duration={2}
                                />{" "}
                                <span className="nicho-txt">NICHO</span>
                            </span>
                        </div>

                        <OverviewItem 
                            title={'Flexible'}
                            volume={totalFlexTokenAmount}
                            apr={flexAPR}
                        />
                        <OverviewItem 
                            title={'Locked'}
                            volume={totalLockedTokenAmount}
                            apr={lockAPR}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
const StakeOverview = () => {
    const { 
        userData, nichoBalance,
        setStakingType, setIsStakeModalVisible, setIsWithdrawModalVisible
    } = useStake();
    
    const { user } = useMoralis();

    const openStakeModal = (stakeType) => {
        setStakingType(stakeType);
        setIsStakeModalVisible(true);
    };
    
    const openWithdrawModal = () => {
        setIsWithdrawModalVisible(true);
    };

    return (
        <div className="earn-bot-content">
            <Row gutter={[16, 26]}>
                {
                    user ? (
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <GlobalOverview />
                        </Col>
                    ): (
                        <Col xs={24}>
                            <GlobalOverview />
                        </Col>
                    )
                }
                
                {
                    user ? 
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div className="tab-card">
                            <div className="card-header">
                                Stake Nicho Token
                            </div>
                            <Divider className="card-header-divider" />
                            <div className="card-content">
                                { user && userData && (
                                <div className="card-content-background">
                                    <div className="content-data">
                                        <div className="txt">
                                            Your NICHO Balance:{" "}
                                            <span className="sub-txt">
                                                <span style={{ color: "purple" }}>
                                                    {Number(nichoBalance).toFixed(FORMAT_DECIMAL)}
                                                </span>{" "}
                                                <span className="nicho-txt">NICHO</span>
                                            </span>
                                        </div>
                                        {
                                            userData.stakedAmount > 0 && <>
                                                <div className="txt">
                                                    Your current position:{" "}
                                                    <span className="sub-txt">
                                                        <span className="nicho-txt" style={{ color: "yellow" }}>
                                                            { userData.lockDays > 0? "Locked Staking": "Flexible Staking"}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="txt">
                                                    Staked Amount:{" "}
                                                    <span className="sub-txt">
                                                        <span style={{ color: "blue" }}>
                                                            {Number(userData.stakedAmount).toFixed(FORMAT_DECIMAL)}
                                                        </span>{" "}
                                                        <span className="nicho-txt">NICHO</span>
                                                    </span>
                                                </div>
                                                
                                                <div className="txt">
                                                    Nicho Earned:{" "}
                                                    <span className="sub-txt">
                                                        <span style={{ color: "green" }}>
                                                            +{Number(userData.earnedAmount).toFixed(FORMAT_DECIMAL)}
                                                        </span>{" "}
                                                        <span className="nicho-txt">NICHO</span>
                                                    </span>
                                                </div>
                                            </>
                                        }
                                        
                                    </div>
                                </div>)}
                                <div className="btn-groups" style={{marginTop: "16px"}}>
                                    {
                                        user && userData && userData.stakedAmount > 0 ? (
                                            <Space size={"large"}>
                                                {
                                                    userData && userData.lockDays > 0? <></> :
                                                    <Button
                                                        //   className="connect-wallet margin-btn"
                                                        onClick={() => {openStakeModal(StakingTypeEnum.Flexible)}}
                                                        type={"primary"}
                                                    >
                                                        Flexible
                                                    </Button>
                                                }
                            
                                                <Button
                                                    //   className="connect-wallet"
                                                    onClick={openWithdrawModal}
                                                >
                                                    Withdraw Nicho
                                                </Button>
                                            </Space>
                                        ): (<Space size={"large"}>
                                            <Button
                                                //   className="connect-wallet margin-btn"
                                                onClick={() => {openStakeModal(StakingTypeEnum.Flexible)}}
                                                type={"primary"}
                                            >
                                                Flexible
                                            </Button>
                                            <Button
                                                //   className="connect-wallet margin-btn"
                                                onClick={() => {openStakeModal(StakingTypeEnum.Locked)}}
                                            >
                                                Locked
                                            </Button>
                                        </Space>)
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </Col>
                    : <></>
                }
            </Row>
        </div>
    )
}

export default StakeOverview;