import { useState, useEffect, useRef } from "react";
import Moralis from "moralis-v1";
import {
  Menu,
  Layout,
  Button,
  Row,
} from "antd";
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { useMoralis, useChain } from "react-moralis";
import { useTranslation } from 'react-i18next';
import { useHeader } from "providers/HeaderProvider/HeaderProvider";

// child components
import Web3AuthAgreeModalComponent from "./components/web3auth_agree_modal";
import NetworkListModalComponent from "./components/network_modal";
import ConnectWalletModal from "./components/walletconnect_modal";
import ProviderWarn from "./components/provider_warn";
import UserAvatarMenu from "./components/user_avatar";
import Web2Wallet from "./Web2Wallet/Web2Wallet";

// Custom modules
import { setNetwork } from "slices/networkSlice";
import { getChainData } from "./chainData";
import { leftMeunItems } from "./menuData";
import { loadDefaultConfig, saveDefaultConfig, openExternalLink, checkTargetAndCurrentHostname, getDefaultChainId } from "./utils";

import "./HeaderContent.scss";
import LogoSvg from "assets/images/new/beta.svg";
import LanguageSelector from "./components/language_menu";
import MobileMenu from "./components/mobile_menu";

const { Header } = Layout;

function HeaderLayout() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Here, chainId is hex string
  const { chain } = useChain();
  const headerRef = useRef();
  const timerRef = useRef();

  const {
    isAuthenticated,
    isInitialized,
    isWeb3Enabled,
    account,
    user,
    logout
  } = useMoralis();

  const { 
    currentChain, 
    prevChain, 
    gotoDiffURL,
    setPrevChain,
    setCurrentChain, 
    isProviderError,
    setIsAuthModalVisible,
    setIsNetworkModalVisible,
    setShowWrongNetworkModal,
    setIsMobileMenuVisible
  } = useHeader();

  // Indicate the active tab
  const [currentLeftMenu, setCurrentLeftMenu] = useState("");

  // When different screen loads, screen should goes up.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Handle for scroll action
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check the token expiration
  useEffect(() => {
    // check if token is expired
    const isTokenExpired = () => {
      const expireTime = localStorage.getItem('authExpireTime')
      return expireTime ? Date.now() > expireTime : true
    }

    // create timer to check token expiration
    if (isAuthenticated && user) {
      if (timerRef) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        if (isTokenExpired()) {
          logout()
        }
      }, 1000)
      // clean up timer
      return () => clearInterval(timerRef.current)
    }
  }, [isAuthenticated, user, logout])

  // whenever chainId changes, need to display correct network on selection.
  useEffect(() => {
    // This means user has not connected his wallet or 
    // He has not any wallet in browser
    // In this case, we need to show default network (BNB chain)
    // If this is testnet, we set default as BNB testnet chain
    const setupWithDefaultConfig = () => {
      const defaultConfig = loadDefaultConfig();
    
      let defaultChainId = "";
      if (defaultConfig && JSON.parse(defaultConfig).chainId) {
        defaultChainId = JSON.parse(defaultConfig).chainId;
      } else  {
        defaultChainId = getDefaultChainId();
      }
      
      let chainInfo = getChainData(defaultChainId);
      if (chainInfo.disabled) {
        defaultChainId = getDefaultChainId();
        chainInfo = getChainData(defaultChainId);
      }
      saveDefaultConfig(defaultChainId);

      setCurrentChain(chainInfo);
      dispatch(setNetwork(defaultChainId));
    }

    // If you have already connected wallet,
    const setupWithConnectedWeb3 = async () => {
      const { chainId } = chain;

      // If wallet is connected? switch all configurations to the connected network.
      const chainInfo = getChainData(chainId);
      setCurrentChain(chainInfo);

      // If this chain is disabled, notifiy about it.
      if (chainInfo.disabled) {
        setShowWrongNetworkModal(true);
        return;
      } 

      // const defaultConfig = loadDefaultConfig();
      // save default config
      saveDefaultConfig(chainId);
        
      const isDiffHost = checkTargetAndCurrentHostname(chainInfo.site_url);
      // If different
      if (isDiffHost !== "same") {
        // If you selected other network in network modal
        if (gotoDiffURL) {
          openExternalLink(chainInfo.site_url);
          return;
        }
        // Initial notification
        setShowWrongNetworkModal(true);
      } else {
        // If network modal opened
        setShowWrongNetworkModal(false);
        // global information
        dispatch(setNetwork(chainId));
      }
    }

    // Monitoring web3 changes
    const monitorNetwork = async () => {
      // If moralis is not initialized
      if (!isInitialized) {
        return;
      }
      if (!isWeb3Enabled || !chain) {
        setupWithDefaultConfig();
        return;
      }
      await setupWithConnectedWeb3();      
    };

    monitorNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, account, isWeb3Enabled, isInitialized]);

  // Mobile menu controller
  const showDrawer = () => {
    setIsMobileMenuVisible(true);
  };
  // Menu controller (main menu items)
  const onLeftMenuClick = (e) => {
    setCurrentLeftMenu(e.key);
  };

  const RightMenu = () => {
    return (
      <div className="rightMenu" style={{ display: 'flex', alignItems: 'center', height: '46px'}}>
        {/* language */}
        <LanguageSelector headerRef={headerRef} />
        
        {/* web2 wallet button */}
        {(user && isAuthenticated && Moralis.connectorType === "web3Auth") && (
          <Web2Wallet currentChain={currentChain} />
        )}
        
        {/* connect wallet button */}
        {(!isAuthenticated || !account) && (
          <Button
            type="primary"
            className="connect-wallet"
            onClick={() => setIsAuthModalVisible(true)}
          >
            {t("connectWallet")}
          </Button>
        )}

        {/* user menu */}
        {isAuthenticated && account && (
          <UserAvatarMenu />
        )}

        {/* network selector */}
        <div style={{ marginLeft: "15px", display: "inline-block" }}>
          <Button
            type="primary"
            className="choose-net"
            onClick={() => {
              setPrevChain(currentChain);
              setIsNetworkModalVisible(true)
            }}
          >
            <Row align="middle">
              <span>
                {prevChain && prevChain.value} &nbsp;&nbsp;&nbsp;{" "}
                {!prevChain && currentChain && currentChain.value} &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <i className="xsj-icon" />
            </Row>
          </Button>
        </div>

        {/* mobile menu bar */}
        <Button className="barsMenu border-none" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
      </div>
    )
  }

  return (
    <Header
      ref={headerRef}
      className={
        offset > 10 ? "header custom-header2" : "header custom-header1"
      }
    >
      {/* Web3auth term and condition modal */}
      <Web3AuthAgreeModalComponent />

      {/* NETWORK LIST modal */}
      <NetworkListModalComponent />

      {/* Wallet List modal */}
      {(!isAuthenticated || !account) && (
        <ConnectWalletModal />
      )}

      {/* Web3 Provider Error */}
      {isProviderError && (<ProviderWarn />)}

      <nav className="menuBar header-content">
        <div className="logo">
          <Link to="/">
            <img
              style={{ width: "180px" }}
              src={LogoSvg}
              alt="Nicho Logo"
              onClick={() => {
                // reset current tab
                setCurrentLeftMenu("");
              }}
            />
          </Link>
        </div>
        <div className="menuCon">
          {/* Left menu */}
          <div className="leftMenu">
            <Menu
              onClick={onLeftMenuClick}
              selectedKeys={[currentLeftMenu]}
              mode="horizontal"
              items={leftMeunItems(t)}
            />
          </div>
          {/* Right menu */}
          <RightMenu />

          {/* Mobile menu */}
          <MobileMenu />
        </div>
      </nav>
    </Header>
  );
}

export default HeaderLayout;
