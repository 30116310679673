import BNBIcon from "assets/images/new/header/bnb.png";
import ETHIcon from "assets/images/new/header/eth.png";
import PolygonIcon from "assets/images/new/header/polygon.png";
import AvalancheIcon from "assets/images/new/header/avalanche.png";
// import FantomIcon from "assets/images/new/header/fantom.png";
// import SuiIcon from "assets/images/new/header/sui.png";
// import AptosIcon from "assets/images/new/header/aptos.png";

// App environment
export const app_env = process.env.REACT_APP_ENVIRONMENT || "testnet";

export const networkConfigs = {
  "0x1": {
    chainId: 1,
    currencySymbol: "ETH",
    blockExplorerUrl: "https://etherscan.io/",
    currencyIcon: ETHIcon,
    wrapped: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    aiDrawPrice: 0.0005,
    pricePair: "ETHUSDT",
    network: "ethereum",
    moralisAppId: "ETHEREUM",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: null,
      marketplaceContract: null,
      auctionContract: null,
      collectionFactoryContract: null,
      rewardContract: null,
      /// ERC20 contract
      nichoTokenContract: null,
      nichoFarmContract: null,
      aiAgentContract: null,
      aiCollectionId: null,
      openAiPaymentReceiverAddress: null,
    },
  },
  "0x5": {
    chainId: 5,
    currencySymbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io/",
    currencyIcon: ETHIcon,
    aiDrawPrice: 0.0005,
    pricePair: "ETHUSDT",
    network: "goerli",
    moralisAppId: "GOERLI",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: null,
      marketplaceContract: null,
      auctionContract: null,
      collectionFactoryContract: null,
      rewardContract: null,
      /// ERC20 contract
      nichoTokenContract: null,
      nichoFarmContract: null,
      aiAgentContract: null,
      aiCollectionId: null,
      openAiPaymentReceiverAddress: null,
    },
  },
  "0x539": {
    chainName: "Local Chain",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "http://127.0.0.1:7545",
    currencyIcon: ETHIcon,
    aiDrawPrice: 0.0005,
    pricePair: "ETHUSDT",
    network: "ethereum",
  },
  "0xa86a": {
    chainId: 43114,
    chainName: "Avalanche Mainnet",
    currencyName: "AVAX",
    currencySymbol: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrl: "https://cchain.explorer.avax.network/",
    currencyIcon: AvalancheIcon,
    aiDrawPrice: 0.02,
    pricePair: "AVAXUSDT",
    network: "avax",
  },
  "0x38": {
    chainId: 56,
    chainName: "Smart Chain",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://bsc-dataseed4.defibit.io/",
    blockExplorerUrl: "https://bscscan.com/",
    wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    currencyIcon: BNBIcon,
    aiDrawPrice: 0.001,
    pricePair: "BNBUSDT",
    network: "bsc",
    moralisAppId: "BSC",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: "0x09262090b30AF49332f697f3601050065EFF647b",
      marketplaceContract: "0xE4083CC4Bc7224F00b8f288d3a8616a325144C4a",
      auctionContract: "0xEA5d5ed29AD686b06c2F52DcFcaA8D80a453A558",
      collectionFactoryContract: "0xbeba016c86683A2D35eb3e6553Ed0a6cA730500D",
      rewardContract: "0x5d92CCFD979155b8DC99610c5019C339dC685Ede",
      /// ERC20 contract
      nichoTokenContract: "0x52904d8bb07e72541c854793242d51128043d527",
      nichoFarmContract: "0xD6E50C880109e633dBE320C679257e79f1F3b239",
      aiAgentContract: "0xfFBcB6dD4624CB95cfC4ceF181d4b1F31cbDFcdd",
      aiCollectionId: "cQ8oPswkJxbr4FHDoztcbg16",
      openAiPaymentReceiverAddress:
        "0xf4769d3E04B3b7969DA72EFd72c896953f8C1621",
    },
  },
  "0x61": {
    chainId: 97,
    chainName: "Smart Chain - Testnet",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    blockExplorerUrl: "https://testnet.bscscan.com/",
    currencyIcon: BNBIcon,
    aiDrawPrice: 0.001,
    pricePair: "BNBUSDT",
    network: "bsc testnet",
    moralisAppId: "BSCTESTNET",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: "0x191837C0D498F1e506CDb5FE81596AE56aFEe71d",
      marketplaceContract: "0xc39f6AE018f4328B1e98b9F72106bfE49e40FE14",
      auctionContract: "0xCD4F8793ce04bDeF0c83727a92112eAf35eCF769",
      collectionFactoryContract: "0xC3aC6258900619409E0102d06566C1b4E3b3f483",
      rewardContract: "0x5f6F33a3462180DD937B5A9DBF7Fd222148fC895",
      /// ERC20 contract
      nichoTokenContract: "0xD04c30cEFd39AE6637BfA6061951BcEd8712F800",
      nichoFarmContract: "0x8A86B596f2BB3b89Ec6cA1ff7b3B76e0cE1469c2",
      aiAgentContract: "0x9A5fB63356767a38aE99D8a5019DcB723554573E",
      aiCollectionId: "dZ0AeaDFjY",
      openAiPaymentReceiverAddress:
        "0x1765E2375e7B8440CA07A92E96F1D4404d52BEf1",
    },
  },
  "0x89": {
    chainId: 137,
    chainName: "Polygon Mainnet",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mainnet.maticvigil.com/",
    blockExplorerUrl: "https://explorer-mainnet.maticvigil.com/",
    wrapped: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    currencyIcon: PolygonIcon,
    aiDrawPrice: 0.1,
    pricePair: "MATICUSDT",
    network: "polygon",
    moralisAppId: "POLYGON",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: "0x412b8930562Cf3f89Ce20FCb7436140D0ffa5740",
      marketplaceContract: "0x24745e000e106d0Df5ad112966e486E4EC28E7db",
      auctionContract: "0xb95930a2f8068893E4d2d23A673a0b049ADd2254",
      collectionFactoryContract: "0x2718F659c33FC9f7D9D666FadB94255503974106",
      rewardContract: "0xEA5d5ed29AD686b06c2F52DcFcaA8D80a453A558",
      /// ERC20 contract
      nichoTokenContract: null,
      nichoFarmContract: null,
      aiAgentContract: "0xd0dab96986BF6a5f8C8e5F9d02ef5Dd5453E2466",
      aiCollectionId: "cQ8oPswkJxbr4FHDoztcbg16",
      openAiPaymentReceiverAddress:
        "0xf4769d3E04B3b7969DA72EFd72c896953f8C1621",
    },
  },
  "0x13881": {
    chainId: 80001,
    chainName: "Mumbai",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mumbai.matic.today/",
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
    currencyIcon: PolygonIcon,
    aiDrawPrice: 0.1,
    pricePair: "MATICUSDT",
    network: "mumbai",
    moralisAppId: "MUMBAI",
    contracts: {
      /// main NichoNFT contract
      nichonftContract: "0x32b00Fe6317B2661DB67D4aC90480ff8153835D5",
      marketplaceContract: "0x5f09d0bd12adAC216528a6D32100A91A86089C54",
      auctionContract: "0x32451a44ca8AAD554Fa8f3Ef3fF833E65EdA1895",
      collectionFactoryContract: "0xBf6232b66dcCfA5EFCd43F0bcAEd743e21822b1E",
      rewardContract: "0x6c397ff6fb94A0e348340bAAe62338ad7052C2bF",
      /// ERC20 contract
      nichoTokenContract: "0x8db25906cf1559870dedcb920be9244c61aa1c9e",
      nichoFarmContract: "0x02275bFF41061A5e9dce84bd964C0F4018d3e7d0",
      aiAgentContract: "0xC9dbBD959CEB6c6617f972A5473f966705535243",
      aiCollectionId: "9XBBp4wGhx",
      openAiPaymentReceiverAddress:
        "0x1765E2375e7B8440CA07A92E96F1D4404d52BEf1",
    },
  },
};

// return the correct https address for blockchain explorer
export const getExplorerAddressFromHexChainId = (chainId, tokenAddress) => {
  const blockExplorerUrl = networkConfigs[chainId].blockExplorerUrl;

  return `${blockExplorerUrl}address/${tokenAddress}`;
};

export const getFullExplorerLink = (explorerLink, address) => {
  return `${explorerLink}address/${address}`;
}

export const getNativeByChain = (chain) =>
  networkConfigs[chain]?.currencySymbol || "NATIVE";

export const getChainById = (chain) => networkConfigs[chain]?.chainId || null;

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain) =>
  networkConfigs[chain]?.wrapped || null;

export const getCurrencyIcon = (chain) =>
  networkConfigs[chain]?.currencyIcon || null;

export const getPricePair = (chain) => networkConfigs[chain]?.pricePair || null;

export const getNetwork = (chain) => networkConfigs[chain]?.network || null;

export const getAllContracts = (chain) =>
  networkConfigs[chain]?.contracts || null;

export const getMoralisAppId = (chain) =>
  networkConfigs[chain]?.moralisAppId || null;

export const getAiDrawPrice = (chain) => networkConfigs[chain]?.aiDrawPrice;
