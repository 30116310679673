import request from './request'

// 
export function getTradeAnalysisDataApi(network) {
  return request({
    url: `/${network}/assets/getTradeAnalysisData`,
    method: 'get',
  })
} 

export function getTradeRankApi(weekOfYear, limit, network) {
  return request({
    url: `/${network}/assets/tradeRank/${weekOfYear}/${limit}`,
    method: 'get',
  })
}

export function getMintRankApi(weekOfYear, limit, network) {
  return request({
    url: `/${network}/assets/mintRank/${weekOfYear}/${limit}`,
    method: 'get',
  })
}


