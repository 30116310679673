import { useEffect, useState } from 'react';
import Introduction from 'components/Home/Introduction';
import RecommendedCollections from 'components/Home/RecommendedCollections';
import Guide from 'components/Home/Guide';
import { useMoralis } from 'react-moralis'

import './Home.scss';
import Partners from './Partners';
import Youtube from './Youtube';
import { useSelector } from 'react-redux';
import { userGetRecommendCollections } from 'api/cloudApi';


function Home() {
  const { isInitialized } = useMoralis();
  const { network } = useSelector(state => state.network);

  const [collections, setCollections] = useState();

  useEffect(() => {
    if (!isInitialized) return;
    const getCollections = async () => {
      
      try {
        const collections = await userGetRecommendCollections(network);
        if (collections) {
            setCollections(collections)
        }   
      } catch (err) {
        console.log(err);
      }
    }
    getCollections();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, network]);

  return (
    <div className="page-content">
      <Introduction />
      <Youtube />
      <RecommendedCollections collections={collections}/>
      <Partners />
      <Guide />
    </div>
  );
}

export default Home;